export const Sizing = {
  wrapperWidth: '1440px',
  gutterWidth: '40px',
  headerHeight: '80px',
  mobileHeaderHeight: '60px',
  mobileWidth: '900px',

  numeric: {
    wrapperWidth: 1440,
    gutterWidth: 40,
    headerHeight: 80,
  },

  borderRadius: {
    buttons: '10px',
  },
};

export const Colors = {
  primary: '#7CC2ED',
  accent: '#154C73',
  black: '#1B1B1B',
  darkGrey: '#454545',
  lightGrey: '#646464',
  lighterGrey: '#b2b2b2',
  white: '#E9EDF1',
  pureWhite: '#FFFFFF',

  red: '#CA2121',
  lightRed: '#FFD9C8',
  darkRed: '#7A1631',

  green: '#36a348',
  lightGreen: '#E9FABB',
  dimmedLightGreen: '#cee396',
  darkGreen: '#35620E',

  lightYellow: '#FFE19B',
  darkYellow: '#7A3200',

  lightBlue: '#D6E4FF',
};
