import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import CheckIcon from '../../../assets/img/CheckIcon';
import CloseIcon from '../../../assets/img/CloseIcon';
import CommentIcon from '../../../assets/img/CommentIcon';
import { CART_ACTIONS } from '../../store/actions';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Button from './Button';
import Input from './Input';

const CartItemCommentWrapper = styled.div`
  position: relative;
  display: block;
  min-height: 31px;
  width: 100%;

  .comment {
    white-space: pre-line;

    &.empty {
      opacity: 0.5;
    }
  }
`;

const CartItemCommentActions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: calc(100% + 12px);

  button {
    min-width: unset;
    padding: 8px;

    & + button {
      margin-top: 8px;
    }
  }
`;

const CartItemComment = ({ product, setCartItemComment }) => {
  const { primaryKey, sCode, sBelowComment } = product;
  const initialComment = sBelowComment?.replace(/<br\s*\/?>/gi, '\n');
  const itemID = primaryKey || sCode;

  const [comment, setCommentState] = useState(initialComment);
  const [isEditing, setIsEditing] = useState(false);

  const setComment = value => {
    setCommentState(
      (value || '').replace(
        /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
        '',
      ),
    );
  };

  const applyComment = () => {
    setIsEditing(false);
    setCartItemComment({ itemID, comment });
  };

  const cancelComment = () => {
    setIsEditing(false);
    setComment(initialComment);
  };

  if (isEditing) {
    return (
      <CartItemCommentWrapper className="title below-comment">
        <Input
          background={Colors.white}
          type="textarea"
          placeholder="Commentaire d'article..."
          value={comment}
          onChange={setComment}
          onBlur={applyComment}
          textarea
        />

        <CartItemCommentActions>
          <Button
            type="button"
            onClick={applyComment}
            background={Colors.white}
            hoverBackground={Colors.primary}
            iconOnly
          >
            <CheckIcon />
          </Button>

          <Button
            type="button"
            onClick={cancelComment}
            background={Colors.white}
            hoverBackground={Colors.red}
            iconOnly
          >
            <CloseIcon />
          </Button>
        </CartItemCommentActions>
      </CartItemCommentWrapper>
    );
  }

  return (
    <CartItemCommentWrapper className="title below-comment">
      {comment ? (
        <div className="comment">{comment}</div>
      ) : (
        <div className="comment empty">Aucun commentaire</div>
      )}

      <CartItemCommentActions>
        <Button
          type="button"
          onClick={() => setIsEditing(true)}
          background={Colors.white}
          hoverBackground={Colors.primary}
          data-tip="Ajouter ou modifier le commentaire"
          iconOnly
        >
          <CommentIcon />
        </Button>

        <ReactTooltip place="top" type="dark" effect="solid" />
      </CartItemCommentActions>
    </CartItemCommentWrapper>
  );
};

CartItemComment.propTypes = {
  product: PropTypes.shape({
    sCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    primaryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sBelowComment: PropTypes.string,
  }).isRequired,

  setCartItemComment: PropTypes.func.isRequired,
};

CartItemComment.defaultProps = {};

export default connect(() => ({}), { ...CART_ACTIONS })(CartItemComment);
