import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { usePageTitle } from '../../hooks';
import { USERS_ACTIONS } from '../../store/actions/reducer-actions/users';
import { Status } from '../../store/models/europrisme';
import { Colors, Sizing } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Button from '../elements/Button';
import FadedImage from '../elements/FadedImage';
import HeroHeader from '../elements/HeroHeader';
import Input from '../elements/Input';
import PageTitle from '../elements/PageTitle';
import ErrorBoundary from '../utils/ErrorBoundary';
import Paths from './paths';

const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  width: 100%;
  max-width: ${Sizing.wrapperWidth};

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    gap: 10px;
  }
`;

const LoginForm = styled.form`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  grid-column: 1 / span 6;
  padding: 30px 0;

  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 12;
  }
`;

const LoginFormInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-column: 2 / span 4;

  h1,
  h1 span.children {
    color: ${Colors.white};
  }

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 6;
    padding: 0 16px;

    h1,
    h1 span.children {
      font-size: 26px;
      line-height: 28px;
    }
  }

  label:first-of-type {
    margin-bottom: 20px;
  }

  label:last-of-type {
    margin-bottom: 40px;
  }
`;

const Login = ({ currentUser, status, loginUser }) => {
  usePageTitle('Connexion');

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const emailInput = useRef();

  const handleSubmit = event => {
    event.preventDefault();

    loginUser(email, password);

    return false;
  };

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus();
    }
  }, [emailInput]);

  useEffect(() => {
    if (currentUser) {
      history.push(Paths.Home());
    }
  }, [currentUser]);

  return (
    <>
      <HeroHeader showSearch={false} fullHeight>
        <FadedImage src="/public/img/hero_header.jpg" x="25%" id="login" />

        <Contents>
          <LoginForm onSubmit={handleSubmit}>
            <ErrorBoundary>
              <LoginFormInner>
                <PageTitle
                  margin="0 0 24px 0"
                  style={{ gridColumn: '1 / span 4' }}
                >
                  Se connecter
                </PageTitle>

                <Input
                  type="email"
                  label="Adresse mail"
                  color={Colors.white}
                  background={Colors.primary}
                  onChange={setEmail}
                  required
                  square
                  disabled={status === Status.LOADING}
                  innerRef={emailInput}
                />

                <Input
                  type="password"
                  label="Mot de passe"
                  color={Colors.white}
                  background={Colors.primary}
                  onChange={setPassword}
                  required
                  square
                  disabled={status === Status.LOADING}
                />

                <Button
                  type="submit"
                  color={Colors.white}
                  background={Colors.primary}
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    height: '45px',
                    marginBottom: '20px',
                  }}
                  disabled={status === Status.LOADING}
                >
                  Se connecter
                </Button>

                <Button
                  type="button"
                  fromComponent={Link}
                  to={Paths.RequestPassword}
                  color={Colors.black}
                  background={Colors.white}
                  hoverBackground={Colors.lightGrey}
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    height: '45px',
                    marginBottom: '10px',
                  }}
                  disabled={status === Status.LOADING}
                >
                  Demande de mot de passe
                </Button>
              </LoginFormInner>
            </ErrorBoundary>
          </LoginForm>
        </Contents>
      </HeroHeader>
    </>
  );
};

Login.propTypes = {
  status: PropTypes.oneOf(Object.values(Status)),
  currentUser: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    v => v === null,
  ]),

  loginUser: PropTypes.func.isRequired,
};

Login.defaultProps = {
  status: Status.IDLE,
  currentUser: null,
};

export const ConnectedLogin = connect(state => ({ ...state.users }), {
  ...USERS_ACTIONS,
})(Login);

export default {
  path: Paths.Login(),
  exact: true,
  name: 'Login',
  component: ConnectedLogin,
};
