import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CancelIcon from '../../../assets/img/CancelIcon';
import ShoppingCartAddIcon from '../../../assets/img/ShoppingCartAddIcon';
import { PUBSUB_TOPICS, useSubscriber } from '../../hooks';
import {
  CART_ACTIONS,
  PENDING_ORDER_ACTIONS,
  PREORDER_ACTIONS,
} from '../../store/actions';
import { ORDER_ACTIONS } from '../../store/actions/reducer-actions/order';
import { Status } from '../../store/models/europrisme';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import DateFormat, { Formats } from '../../utils/date';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import Button from './Button';
import Confirm from './Confirm';
import OrderSingleRow from './OrderSingleRow';
import PageTitle from './PageTitle';
import Spinner from './Spinner';

const OrderDetailsWrapper = styled.div`
  position: relative;
  height: 100%;

  .spinner-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 54px;
    padding: 0 50px;

    @media all and (max-width: ${Sizing.mobileWidth}) {
      padding: 0 16px;
      align-items: center;
    }

    h2 {
      height: 55px;
      margin: 0;

      span.children {
        margin-left: 12px;
        font-size: 20px;
      }

      @media all and (max-width: ${Sizing.mobileWidth}) {
        svg {
          display: none;
        }

        span.children {
          margin-left: 0 !important;
          font-size: 16px !important;
        }
      }
    }
  }

  .content {
    position: relative;
    display: block;
    height: calc(100% - 54px - 20px);
    padding: 0 50px;
    margin-top: 20px;

    overflow-y: auto;

    @media all and (max-width: ${Sizing.mobileWidth}) {
      padding: 0 16px;
    }

    > :first-child {
      margin-top: 20px;
    }
  }
`;

const OrderRepeat = styled(Button)`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-self: flex-end;
  right: 50px;
  padding: 10px 20px !important;

  border-radius: 3px !important;
  font-size: 18px;

  box-shadow: 0 0 0 3px ${props => props.$borderColor || Colors.accent} !important;

  transition-property: color, background-color !important;

  z-index: 10;

  svg {
    margin-right: 0 !important;
    margin-bottom: 6px;
  }

  @media all and (max-width: ${Sizing.mobileWidth}) {
    flex-direction: row !important;
    min-width: unset !important;
    padding: 4px 8px !important;
    align-self: unset !important;

    font-size: 12px !important;
    box-shadow: 0 0 0 2px ${Colors.accent} !important;

    svg {
      margin-right: 6px !important;
      margin-bottom: 0 !important;
    }
  }
`;

const OrderTitleRow = styled.div`
  margin-top: 0 !important;
  margin-bottom: 20px;

  font-size: 18px;
  font-weight: 500;

  & + .comment-row {
    margin-top: -10px !important;
  }
`;

const OrderCommentRow = styled.div`
  margin-top: 0 !important;
  margin-bottom: 20px;
`;

const OrderNotFound = () => {
  return (
    <OrderDetailsWrapper>
      <header>
        <PageTitle fromComponent="h2">Commande non trouvée</PageTitle>
      </header>
    </OrderDetailsWrapper>
  );
};

// ============================================================================

const OrderDetails = ({
  code,
  orders,
  productsState,
  getSingleOrder,
  addToCart,
  addInvalidToCart,
  saveCurrentCartToStorage,
  setOrderComment,
}) => {
  const history = useHistory();

  const order = orders[code];

  useEffect(() => {
    if (order && order.status !== Status.LOADING && !order.records) {
      getSingleOrder(code);
    }
  }, [order, code]);

  if (!order) {
    return <OrderNotFound />;
  }

  const { date, comment, status, records } = order;

  const formattedDate = DateFormat.format(date, Formats.ORDER_HUMAN_READABLE);
  const isLoading = status === Status.LOADING;

  let validProducts = [];
  let invalidProducts = [];

  const { products } = productsState;

  if (records) {
    const orderProductIDs = records.map(
      ({ sOrderLineArticlecode }) => sOrderLineArticlecode,
    );

    const isProductValid = ({ sCode, id, status: productStatus }) =>
      ![Status.LOADING, Status.ERROR].includes(productStatus) &&
      (orderProductIDs.includes(id) || orderProductIDs.includes(`${sCode}`));

    validProducts = records
      .map(record => {
        const { sOrderLineArticlecode, sOrderLineBelowComment } = record;

        const product = products[sOrderLineArticlecode];
        const productComment = sOrderLineBelowComment || null;

        return {
          product: {
            ...product,

            primaryKey:
              productComment &&
              productComment.match(
                /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
              )
                ? `__INVALID/${productComment.replace(
                    /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
                    '',
                  )}`
                : sOrderLineArticlecode,

            sCode: sOrderLineArticlecode,
            sBelowComment: productComment,
            isDummy: !!productComment,
          },

          record,
        };
      })
      .filter(({ product }) => isProductValid(product));

    invalidProducts = Object.values(products)
      .filter(product => !isProductValid(product))
      .map(product => ({
        product,
        record: records.find(({ sOrderLineArticlecode }) =>
          [product.id, `${product.sCode}`].includes(sOrderLineArticlecode),
        ),
      }));
  }

  const repeatOrderCommit = ({ useOrderComment, useLineComments }) => {
    validProducts.forEach(({ product, record }) => {
      const {
        sOrderLineQuantity,
        sOrderLineArticlecode: sArticleCode,
        sOrderLineBelowComment: sBelowComment,
      } = record;

      const count = Number.parseInt(sOrderLineQuantity, 10);
      const productComment = sBelowComment || null;

      const isDummy =
        productComment &&
        productComment.match(
          /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
        );

      addToCart(
        {
          ...product,

          primaryKey: isDummy
            ? `__INVALID/${productComment.replace(
                /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
                '',
              )}`
            : sArticleCode,

          sCode: sArticleCode,
          sBelowComment: useLineComments || isDummy ? productComment : null,
          isDummy,
        },
        count,
      );
    });

    invalidProducts.forEach(({ product, record }) => {
      if (record) {
        const { sOrderLineQuantity } = record;
        const count = Number.parseInt(sOrderLineQuantity, 10);

        addInvalidToCart(
          {
            ...product,
            sBelowComment: record?.sOrderLineBelowComment,
          },
          count,
        );
      }
    });

    if (useOrderComment) {
      setOrderComment(comment);
    }

    saveCurrentCartToStorage();
    history.push(Paths.Cart());
  };

  const repeatOrderWithLineComments = ({ useOrderComment }) => {
    if (appConfig.get('settings.useCartItemComment')) {
      Confirm({
        title: 'Reprise des commentaires',
        message: [
          'Voulez-vous reprendre les commentaires de lignes de commande dans votre panier ?',
          'Ceux-ci seront visibles dans votre intention de commande.',
        ],

        acceptButtonLabel: 'Oui',
        onConfirm: () =>
          repeatOrderCommit({ useOrderComment, useLineComments: true }),

        rejectButtonLabel: 'Non',
        onReject: () =>
          repeatOrderCommit({ useOrderComment, useLineComments: false }),
      })();
    } else {
      repeatOrderCommit({ useOrderComment, useLineComments: false });
    }
  };

  const repeatOrder = () => {
    if (comment) {
      Confirm({
        title: 'Reprise des commentaires',
        message: [
          'Voulez-vous reprendre le commentaire de commande dans votre panier ?',
          'Celui-ci sera visible dans votre intention de commande.',

          // eslint-disable-next-line react/no-danger
          <blockquote dangerouslySetInnerHTML={{ __html: comment }} />,
        ],

        acceptButtonLabel: 'Oui',
        onConfirm: () => repeatOrderWithLineComments({ useOrderComment: true }),

        rejectButtonLabel: 'Non',
        onReject: () => repeatOrderWithLineComments({ useOrderComment: false }),
      })();
    } else {
      repeatOrderWithLineComments({ useOrderComment: false });
    }
  };

  const allRecordsLoaded =
    records &&
    records
      .map(({ sOrderLineArticlecode }) => products[sOrderLineArticlecode])
      .every(product => product && product.status !== Status.LOADING);

  return (
    <OrderDetailsWrapper>
      <header>
        <PageTitle fromComponent="h2" lineHeight="1.2">
          <div className="title">Dossier n°{code}</div>
          <div className="sub dim">{formattedDate}</div>
        </PageTitle>

        {records && records.length > 0 && allRecordsLoaded && (
          <OrderRepeat
            color={Colors.black}
            background={Colors.white}
            hoverColor={Colors.white}
            hoverBackground={Colors.accent}
            onClick={repeatOrder}
          >
            <ShoppingCartAddIcon size={24} />
            <span>Reprise de commande</span>
          </OrderRepeat>
        )}
      </header>

      {isLoading ? (
        <div className="spinner-wrapper">
          <Spinner>Chargement...</Spinner>
        </div>
      ) : (
        <div className="content">
          {comment && (
            <OrderCommentRow dangerouslySetInnerHTML={{ __html: comment }} />
          )}

          {records &&
            records.map(
              (
                {
                  sOrderLineArticlecode,
                  sOrderLineQuantity,
                  sOrderLineArticleLibelle,
                  sOrderLineBelowComment,
                },
                index,
              ) => (
                <OrderSingleRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}--${sOrderLineArticlecode}`}
                  productID={sOrderLineArticlecode}
                  quantity={sOrderLineQuantity}
                  label={sOrderLineArticleLibelle}
                  belowComment={sOrderLineBelowComment}
                />
              ),
            )}
        </div>
      )}
    </OrderDetailsWrapper>
  );
};

OrderDetails.propTypes = {
  code: PropTypes.string.isRequired,

  orders: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  productsState: PropTypes.objectOf(PropTypes.any).isRequired,

  getSingleOrder: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  addInvalidToCart: PropTypes.func.isRequired,
  saveCurrentCartToStorage: PropTypes.func.isRequired,
  setOrderComment: PropTypes.func.isRequired,
};

// ============================================================================

const PreorderDetails = ({
  code,
  preordersState,
  productsState,
  getSinglePreorder,
  sendPreorderLinesAsPreorder,
  addToCart,
  saveCurrentCartToStorage,
  setOrderComment,
}) => {
  const history = useHistory();

  const { preorders } = preordersState;
  const preorder = preorders[code];

  useEffect(() => {
    if (preorder && preorder.status !== Status.LOADING && !preorder.records) {
      getSinglePreorder(code);
    }
  }, [preorder, code]);

  if (!preorder || (preorder.records && !preorder.records[0])) {
    return <OrderNotFound />;
  }

  const { date, title, comment, status, records } = preorder;

  const formattedDate = DateFormat.format(date, Formats.ORDER_HUMAN_READABLE);
  const isLoading = status === Status.LOADING;

  let validProducts = [];

  const { products } = productsState;

  if (records) {
    validProducts = records.map(record => {
      const { sG5ArticleCode, sOrderLineBelowComment } = record;

      const product = products[sG5ArticleCode];
      const productComment = sOrderLineBelowComment || null;

      return {
        product: {
          ...product,

          primaryKey:
            productComment &&
            productComment.match(
              /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
            )
              ? `__INVALID/${productComment.replace(
                  /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
                  '',
                )}`
              : sG5ArticleCode,

          sCode: sG5ArticleCode,
          sBelowComment: productComment,
          isDummy: !!productComment,
        },

        record,
      };
    });
  }

  const repeatOrderCommit = ({ useOrderComment, useLineComments }) => {
    validProducts.forEach(({ product, record }) => {
      const { sArticleCode, iQuantity, sBelowComment } = record;

      const count = Number.parseInt(iQuantity, 10);
      const productComment = sBelowComment || null;

      const isDummy =
        sBelowComment &&
        sBelowComment.match(/^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i);

      addToCart(
        {
          ...product,

          primaryKey: isDummy
            ? `__INVALID/${productComment.replace(
                /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
                '',
              )}`
            : sArticleCode,

          sBelowComment: useLineComments || isDummy ? sBelowComment : null,
          isDummy,
        },

        count,
      );
    });

    if (useOrderComment) {
      setOrderComment(comment);
    }

    saveCurrentCartToStorage();
    history.push(Paths.Cart());
  };

  const repeatOrderWithLineComments = ({ useOrderComment }) => {
    if (appConfig.get('settings.useCartItemComment')) {
      Confirm({
        title: 'Reprise des commentaires',
        message: [
          'Voulez-vous reprendre les commentaires de lignes de commande dans votre panier ?',
          'Ceux-ci seront visibles dans votre intention de commande.',
        ],

        acceptButtonLabel: 'Oui',
        onConfirm: () =>
          repeatOrderCommit({
            useOrderComment,
            useLineComments: true,
          }),

        rejectButtonLabel: 'Non',
        onReject: () =>
          repeatOrderCommit({
            useOrderComment,
            useLineComments: false,
          }),
      })();
    } else {
      repeatOrderCommit({ useOrderComment, useLineComments: false });
    }
  };

  const repeatOrder = () => {
    if (comment) {
      Confirm({
        title: 'Reprise des commentaires',
        message: [
          'Voulez-vous reprendre le commentaire de commande dans votre panier ?',
          'Celui-ci sera visible dans votre intention de commande.',

          // eslint-disable-next-line react/no-danger
          <blockquote dangerouslySetInnerHTML={{ __html: comment }} />,
        ],

        acceptButtonLabel: 'Oui',
        onConfirm: () => repeatOrderWithLineComments({ useOrderComment: true }),

        rejectButtonLabel: 'Non',
        onReject: () => repeatOrderWithLineComments({ useOrderComment: false }),
      })();
    } else {
      repeatOrderWithLineComments({ useOrderComment: false });
    }
  };

  const allRecordsLoaded =
    records &&
    records
      .map(({ sG5ArticleCode }) => products[sG5ArticleCode])
      .every(product => product && product.status !== Status.LOADING);

  return (
    <OrderDetailsWrapper>
      <header>
        <PageTitle
          fromComponent="h2"
          lineHeight="1"
          style={{ marginRight: '12px' }}
        >
          Précommande n°{code} du {formattedDate}
        </PageTitle>

        {records && records.length > 0 && allRecordsLoaded && (
          <OrderRepeat
            color={Colors.black}
            background={Colors.white}
            hoverColor={Colors.white}
            hoverBackground={Colors.accent}
            onClick={repeatOrder}
          >
            <ShoppingCartAddIcon size={24} />
            <span>Reprise de précommande</span>
          </OrderRepeat>
        )}
      </header>

      {isLoading ? (
        <div className="spinner-wrapper">
          <Spinner>Chargement...</Spinner>
        </div>
      ) : (
        <div className="content">
          {title && (
            <OrderTitleRow className="title-row">{title}</OrderTitleRow>
          )}

          {comment && (
            <OrderCommentRow
              className="comment-row"
              dangerouslySetInnerHTML={{ __html: comment }}
            />
          )}

          {records &&
            records.map(
              ({
                kIDPreOrder,
                kIDPreOrderLine,
                sG5ArticleCode,
                iQuantity,
                sBelowComment,
              }) => {
                const onDelete = () => {
                  const filteredRecords = records.filter(
                    ({ kIDPreOrderLine: id }) => id !== kIDPreOrderLine,
                  );

                  sendPreorderLinesAsPreorder({
                    products: filteredRecords,
                    title,
                    comment,
                    preorderID: kIDPreOrder,

                    __hardRefresh: true,
                  });
                };

                return (
                  <OrderSingleRow
                    key={`${kIDPreOrder}--${kIDPreOrderLine}`}
                    productID={sG5ArticleCode}
                    quantity={iQuantity}
                    label="???"
                    deletable
                    onDelete={onDelete}
                    belowComment={sBelowComment}
                  />
                );
              },
            )}
        </div>
      )}
    </OrderDetailsWrapper>
  );
};

PreorderDetails.propTypes = {
  code: PropTypes.string.isRequired,

  preordersState: PropTypes.objectOf(PropTypes.any).isRequired,
  productsState: PropTypes.objectOf(PropTypes.any).isRequired,

  getSinglePreorder: PropTypes.func.isRequired,
  sendPreorderLinesAsPreorder: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  saveCurrentCartToStorage: PropTypes.func.isRequired,
  setOrderComment: PropTypes.func.isRequired,
};

// ============================================================================

const PendingOrderDetails = ({
  code,
  pendingOrdersState,
  getSinglePendingOrder,
  cancelPendingOrder,
  getPendingOrders,
}) => {
  const history = useHistory();

  const { pendingOrders } = pendingOrdersState;
  const order = pendingOrders[code];

  useEffect(() => {
    if (order && order.status !== Status.LOADING && !order.records) {
      getSinglePendingOrder(code);
    }
  }, [order, code]);

  useSubscriber(PUBSUB_TOPICS.PENDING_ORDER_CANCELLED, () => {
    getPendingOrders();
    history.push(Paths.PendingOrdersList({ orderID: '' }));
  });

  if (!order) {
    return <OrderNotFound />;
  }

  const { date, comment, status, records, cancelled } = order;

  const formattedDate = DateFormat.format(date, Formats.ORDER_HUMAN_READABLE);
  const isLoading = status === Status.LOADING;

  const cancelOrder = () => {
    cancelPendingOrder(code);
  };

  const cancelOrderWithConfirm = () => {
    Confirm({
      title: 'Annuler la commande',
      message: [
        'Êtes-vous sûr(e) de vouloir annuler cette commande ?',
        'Celle-ci sera supprimée définitivement.',
      ],

      acceptButtonLabel: 'Oui',
      onConfirm: () => cancelOrder(),

      rejectButtonLabel: 'Non',
      onReject: () => {},
    })();
  };

  return (
    <OrderDetailsWrapper>
      <header>
        <PageTitle fromComponent="h2" lineHeight="1.2">
          <div className="title">Dossier n°{code}</div>
          <div className="sub dim">{formattedDate}</div>
        </PageTitle>

        {!cancelled && (
          <OrderRepeat
            color={Colors.black}
            background={Colors.white}
            hoverColor={Colors.white}
            hoverBackground={Colors.red}
            onClick={cancelOrderWithConfirm}
            $borderColor={Colors.red}
          >
            <CancelIcon size={24} />
            <span>Annuler la commande</span>
          </OrderRepeat>
        )}
      </header>

      {isLoading ? (
        <div className="spinner-wrapper">
          <Spinner>Chargement...</Spinner>
        </div>
      ) : (
        <div className="content">
          {comment && (
            <OrderCommentRow dangerouslySetInnerHTML={{ __html: comment }} />
          )}

          {records &&
            records.map(
              (
                {
                  sOrderLineArticlecode,
                  sOrderLineQuantity,
                  sOrderLineArticleLibelle,
                  sOrderLineBelowComment,
                },
                index,
              ) => (
                <OrderSingleRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}--${sOrderLineArticlecode}`}
                  productID={sOrderLineArticlecode}
                  quantity={sOrderLineQuantity}
                  label={sOrderLineArticleLibelle}
                  belowComment={sOrderLineBelowComment}
                />
              ),
            )}
        </div>
      )}
    </OrderDetailsWrapper>
  );
};

PendingOrderDetails.propTypes = {
  code: PropTypes.string.isRequired,

  pendingOrdersState: PropTypes.objectOf(PropTypes.any).isRequired,

  getSinglePendingOrder: PropTypes.func.isRequired,
  cancelPendingOrder: PropTypes.func.isRequired,
  getPendingOrders: PropTypes.func.isRequired,
};

// ============================================================================

const GenericOrderDetails = ({ type, ...rest }) => {
  switch (type) {
    case 'order':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <OrderDetails {...rest} />;

    case 'preorder':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <PreorderDetails {...rest} />;

    case 'pendingOrder':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <PendingOrderDetails {...rest} />;

    default:
      throw new Error(`Unknown order details type: ${type}`);
  }
};

GenericOrderDetails.propTypes = {
  type: PropTypes.string,
};

GenericOrderDetails.defaultProps = {
  type: 'order',
};

export default connect(
  state => ({
    ...state.order,
    preordersState: state.preorders,
    pendingOrdersState: state.pendingOrders,
    productsState: state.products,
  }),
  {
    ...ORDER_ACTIONS,
    ...PREORDER_ACTIONS,
    ...PENDING_ORDER_ACTIONS,
    ...CART_ACTIONS,
  },
)(GenericOrderDetails);
