/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../styles/vars';

const StyledInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  width: 100%;
  height: 40px;
  padding: 0 ${props => (props.square ? '15px' : '30px')};

  background: ${props => props.background};
  border: none;
  border-radius: ${props => (props.square ? 0 : '20px')};
  outline: none;

  color: ${props => props.color};
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;

  transition: opacity 0.25s;

  &::placeholder {
    color: ${props => props.color};
    opacity: 0.25;
  }

  &::selection {
    color: ${props => props.background} !important;
    background: ${props => props.color} !important;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.background} inset !important;
    -webkit-text-fill-color: ${props => props.color} !important;
    caret-color: ${props => props.color} !important;
  }
`;

const StyledTextarea = styled.textarea`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  width: 100%;
  height: 200px;
  padding: ${props => (props.square ? '15px' : '30px')};
  resize: none;

  background: ${props => props.background};
  border: none;
  border-radius: ${props => (props.square ? 0 : '20px')};
  outline: none;

  color: ${props => props.color};
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;

  transition: opacity 0.25s;

  &::placeholder {
    color: ${props => props.color};
    opacity: 0.25;
  }

  &::selection {
    color: ${props => props.background} !important;
    background: ${props => props.color} !important;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.$color || Colors.white};
  }

  ${props =>
    props.required &&
    css`
      span {
        &::after {
          content: '*';
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-left: 0.5ch;
          transform: translateY(3px);

          font-weight: bold;
          color: ${Colors.primary};
        }
      }
    `}

  ${props =>
    props.padLabel &&
    css`
      span {
        padding-left: ${props.square ? '8px' : '15px'};
      }
    `}
`;

const Input = ({
  label,
  labelColor,
  type,
  placeholder,
  square,
  color,
  background,
  required,
  disabled,
  textarea,
  readonly,
  padLabel,
  style,
  labelStyle,
  innerRef,
  value: initialValue,
  onChange: forwardOnChange,
  onKeyDown: forwardOnKeyDown,
  onBlur: forwardOnBlur,
}) => {
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleOnChange = event => {
    if (forwardOnChange) {
      forwardOnChange(event.target.value);
    }

    setValue(event.target.value);
  };

  const renderInput = () => {
    if (textarea || type.toLowerCase() === 'textarea') {
      return (
        <StyledTextarea
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          onKeyPress={forwardOnKeyDown}
          onBlur={forwardOnBlur}
          square={square ? 1 : 0}
          color={color}
          background={background}
          required={required}
          disabled={disabled}
          readonly={readonly}
          style={style}
          ref={innerRef}
        />
      );
    }

    return (
      <StyledInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        onKeyDown={forwardOnKeyDown}
        onBlur={forwardOnBlur}
        square={square ? 1 : 0}
        color={color}
        background={background}
        required={required}
        disabled={disabled}
        readonly={readonly}
        style={style}
        ref={innerRef}
      />
    );
  };

  if (label) {
    return (
      <Label
        $color={labelColor}
        style={labelStyle}
        required={required ? 1 : 0}
        padLabel={padLabel}
      >
        <span>{label}</span>
        {renderInput()}
      </Label>
    );
  }

  return renderInput();
};

Input.propTypes = {
  label: PropTypes.string,
  labelColor: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  square: PropTypes.bool,
  color: PropTypes.string,
  background: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  textarea: PropTypes.bool,
  readonly: PropTypes.bool,
  padLabel: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  labelStyle: PropTypes.objectOf(PropTypes.any),
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  value: PropTypes.string,

  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  label: null,
  labelColor: Colors.white,
  placeholder: '',
  square: false,
  color: Colors.black,
  background: 'white',
  required: false,
  disabled: false,
  textarea: false,
  readonly: false,
  padLabel: false,
  style: null,
  labelStyle: null,
  value: '',

  onChange: null,
  onKeyPress: null,
  onBlur: null,
};

export default memo(Input);
