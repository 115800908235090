import { isArray } from 'lodash';

import {
  NotificationType,
  sendNotification,
} from '../../components/elements/NotificationArea';
import {
  AMD_AUTH_REJECTED,
  AMD_AUTH_REQUESTED,
  AMD_AUTH_RESOLVED,
  CART_CREATE_VIRTUAL_REJECTED,
  CART_CREATE_VIRTUAL_REQUESTED,
  CART_CREATE_VIRTUAL_RESOLVED,
  CART_GET_VIRTUAL_REJECTED,
  CART_GET_VIRTUAL_REQUESTED,
  CART_GET_VIRTUAL_RESOLVED,
  HARTMANN_AUTH_REJECTED,
  HARTMANN_AUTH_REQUESTED,
  HARTMANN_AUTH_RESOLVED,
  ONTEX_AUTH_REJECTED,
  ONTEX_AUTH_REQUESTED,
  ONTEX_AUTH_RESOLVED,
} from '../actions/types';
import { Status } from './cart';

const initialState = {
  carts: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CART_CREATE_VIRTUAL_REQUESTED: {
      const { params } = action.payload;
      const { sUID } = params;

      const cartData = {
        ...(state.carts[sUID] || { sUID }),
        status: Status.LOADING,
      };

      const carts = {
        ...state.carts,
        [sUID]: cartData,
      };

      return { ...state, carts };
    }

    case CART_CREATE_VIRTUAL_RESOLVED: {
      const { response, params } = action.payload;
      const { sUID, sVirtualCartID } = params;

      const uuid = sUID || sVirtualCartID;

      const cartData = {
        ...(state.carts[uuid] || { sUID: uuid, response }),
        status: Status.LOADED,
      };

      const carts = {
        ...state.carts,
        [uuid]: cartData,
      };

      return { ...state, carts };
    }

    case CART_CREATE_VIRTUAL_REJECTED:
    case CART_GET_VIRTUAL_REJECTED: {
      const { params } = action.payload;
      const { sUID } = params;

      const cartData = {
        ...(state.carts[sUID] || { sUID }),
        status: Status.ERROR,
      };

      const carts = {
        ...state.carts,
        [sUID]: cartData,
      };

      return { ...state, carts };
    }

    case CART_GET_VIRTUAL_REQUESTED: {
      const { params } = action.payload;
      const { sUID } = params;

      const cartData = {
        ...(state.carts[sUID] || { sUID }),
        status: Status.LOADING,
        products: [],
      };

      const carts = {
        ...state.carts,
        [sUID]: cartData,
      };

      return { ...state, carts };
    }

    case CART_GET_VIRTUAL_RESOLVED: {
      const { response, params } = action.payload;
      const { sUID } = params;

      const { xmlst } = response;
      const { Records } = xmlst;
      const { Item } = Records;

      const cartData = {
        ...(state.carts[sUID] || { sUID }),
        status: Status.IDLE,
        products: isArray(Item) ? Item : [Item],
      };

      const carts = {
        ...state.carts,
        [sUID]: cartData,
      };

      return { ...state, carts };
    }

    case ONTEX_AUTH_REQUESTED:
    case HARTMANN_AUTH_REQUESTED:
    case AMD_AUTH_REQUESTED: {
      const { params } = action.payload;
      const { sUID, sVirtualCartID } = params;

      const uuid = sUID || sVirtualCartID;

      const cartData = {
        ...(state.carts[uuid] || { sUID: uuid }),
        status: Status.AUTHENTICATING,
      };

      const carts = {
        ...state.carts,
        [uuid]: cartData,
      };

      return { ...state, carts };
    }

    case ONTEX_AUTH_RESOLVED:
    case HARTMANN_AUTH_RESOLVED:
    case AMD_AUTH_RESOLVED: {
      const { params, response } = action.payload;
      const { sUID, sVirtualCartID } = params;

      const uuid = sUID || sVirtualCartID;

      const cartData = {
        ...(state.carts[uuid] || { sUID: uuid }),
        status: Status.AUTHENTICATED,
        response,
      };

      const carts = {
        ...state.carts,
        [uuid]: cartData,
      };

      return { ...state, carts };
    }

    case ONTEX_AUTH_REJECTED:
    case HARTMANN_AUTH_REJECTED:
    case AMD_AUTH_REJECTED: {
      const { params } = action.payload;
      const { sUID, sVirtualCartID } = params;

      const uuid = sUID || sVirtualCartID;

      const cartData = {
        ...(state.carts[uuid] || { sUID: uuid }),
        status: Status.ERROR,
      };

      const carts = {
        ...state.carts,
        [uuid]: cartData,
      };

      const platformName = {
        ONTEX_AUTH_REJECTED: 'Ontex',
        HARTMANN_AUTH_REJECTED: 'Hartmann',
        AMD_AUTH_REJECTED: 'AMD',
      }[action.type];

      if (platformName) {
        sendNotification(
          `Vous n'êtes pas autorisés à accéder à la plateforme ${platformName}`,
          NotificationType.WARNING,
        );
      }

      return { ...state, carts };
    }

    default:
      return state;
  }
};
