import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Circle = styled.span`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;
  padding: 0 5px;

  color: #ffffff;
  background-color: ${props => props.color};
  border: 3px solid ${props => props.shade || 'rgba(255, 255, 255, 0.5)'};
  border-radius: 26px;

  font-size: 10px;

  @media print {
    display: none;
  }
`;

const CircleLink = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;

  color: #ffffff;
  background-color: ${props => props.color};
  border: 3px solid ${props => props.shade || 'rgba(255, 255, 255, 0.5)'};
  border-radius: 26px;

  font-size: 12px;
  line-height: 15px;
  text-decoration: none;

  @media print {
    display: none;
  }
`;

const CircleButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;
  padding: 0;

  color: #ffffff;
  background-color: ${props => props.color};
  border: 3px solid ${props => props.shade || 'rgba(255, 255, 255, 0.5)'};
  border-radius: 26px;
  outline: none;

  font-size: 12px;
  line-height: 15px;
  text-decoration: none;

  cursor: pointer;

  @media print {
    display: none;
  }
`;

const CircleFloater = styled.div`
  position: relative;
  height: 0;
  overflow: visible;
  margin-right: -12px;

  .spacer {
    position: relative;
    visibility: hidden;
    height: 0;

    opacity: 0;

    pointer-events: none;
  }

  .actual {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media print {
    display: none;
  }
`;

const HeaderCircle = ({ color, shade, floating, to, onClick, children }) => {
  let CircleComponent;

  if (to) {
    CircleComponent = (
      <CircleLink
        className="header-circle"
        to={to}
        color={color}
        shade={shade}
        floating={floating}
      >
        {children}
      </CircleLink>
    );
  } else if (onClick) {
    CircleComponent = (
      <CircleButton
        className="header-circle"
        onClick={onClick}
        color={color}
        shade={shade}
        floating={floating}
      >
        {children}
      </CircleButton>
    );
  } else {
    CircleComponent = (
      <Circle
        className="header-circle"
        color={color}
        shade={shade}
        floating={floating}
      >
        {children}
      </Circle>
    );
  }

  if (floating) {
    return (
      <CircleFloater>
        <div className="spacer">{CircleComponent}</div>
        <div className="actual">{CircleComponent}</div>
      </CircleFloater>
    );
  }

  return CircleComponent;
};

HeaderCircle.propTypes = {
  color: PropTypes.string,
  shade: PropTypes.string,
  floating: PropTypes.bool,
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

HeaderCircle.defaultProps = {
  color: '#454545',
  shade: null,
  floating: false,
  children: null,
  to: null,
  onClick: null,
};

export default HeaderCircle;
