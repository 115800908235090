import 'react-confirm-alert/src/react-confirm-alert.css';

import { flatten } from 'lodash';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import styled, { createGlobalStyle } from 'styled-components';

import { Colors, Sizing } from '../../styles/vars';
import Button from './Button';
import PageTitle from './PageTitle';

const ConfirmStyleOverrides = createGlobalStyle`
  .react-confirm-alert-blur {
    -webkit-filter: none !important;
    filter: none !important;
    filter: none !important;
    -webkit-filter: none !important;
  }

  .react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);

    z-index: 999;
  }
`;

const ConfirmContainer = styled.div`
  display: block;
  padding: 40px;
  min-width: 550px;
  max-width: 700px;

  background-color: ${Colors.white};
  border-radius: 20px;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    min-width: unset;
    width: calc(100% - 32px);
    margin: 0 auto;
    padding: 20px 16px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 30px;

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      font-size: 20px;
      margin-bottom: 6px;

      .children {
        font-size: inherit !important;
      }
    }
  }

  p {
    margin: 5px 0;
  }

  blockquote {
    margin: 0;
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 12px 16px;

    white-space: pre-line;
    font-size: 13px;
    background-color: ${Colors.lighterGrey}40;
    border-left: 4px solid ${Colors.lighterGrey};
    border-radius: 1px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    button + button {
      margin-left: 20px;
    }

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      margin-top: 20px;

      button {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
      }

      button + button {
        margin-left: 12px;
      }
    }
  }
`;

export default ({
  title,
  message,
  additionalChildren,
  onConfirm,
  onReject,
  rejectButtonLabel,
  rejectButtonProps,
  acceptButtonLabel,
  acceptButtonProps,

  skipRejectButton,
}) => () =>
  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => (
      <>
        <ConfirmStyleOverrides />
        <ConfirmContainer>
          <PageTitle>{title}</PageTitle>

          {flatten([message]).map((line, i) => {
            if (React.isValidElement(line)) {
              const LineComponent = () => line;

              // eslint-disable-next-line react/no-array-index-key
              return <LineComponent key={i} />;
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <p key={i}>{line}</p>
            );
          })}

          {additionalChildren}

          <div className="actions">
            {!skipRejectButton && (
              <Button
                background={Colors.accent}
                color={Colors.white}
                onClick={() => {
                  onClose();
                  if (onReject) onReject();
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rejectButtonProps}
              >
                {rejectButtonLabel || 'Non'}
              </Button>
            )}

            <Button
              onClick={() => {
                onClose();
                if (onConfirm) onConfirm();
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...acceptButtonProps}
            >
              {acceptButtonLabel || 'Oui'}
            </Button>
          </div>
        </ConfirmContainer>
      </>
    ),
  });
