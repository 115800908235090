import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../styles/vars';

const NumberInputWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  background-color: white;
  border-radius: 40px;

  span {
    margin-left: 15px;
    margin-right: 30px;
    font-size: 8px;
    line-height: 9px;
  }

  input {
    width: ${props => (props.small ? '3ch' : '5ch')};
    margin: 0 ${props => (props.small ? '0' : '10px')};

    font-size: 14px;
    line-height: 17px;

    background: none;
    border: none;
    outline: none;
    text-align: center;

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const NumberInputButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.small ? '20px' : '30px')};
  height: ${props => (props.small ? '20px' : '30px')};

  ${props =>
    props.small &&
    css`
      padding: 0;
    `}

  font-size: ${props => (props.small ? '10px' : '16px')};
  font-weight: 300;
  color: ${Colors.lightGrey};
  background: ${Colors.white};
  border: none;
  border-radius: 30px;
  outline: none;

  opacity: 1;
  transition: opacity 0.4s ease;

  cursor: pointer;
  user-select: none;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const NumberInput = ({
  value,
  min,
  max,
  id,
  small,
  style,
  onChange: parentOnChangeCallback,
  children,
  ...rest
}) => {
  const [count, setCount] = useState(Number.parseInt(value, 10));

  const validate = v => Math.min(max, Math.max(min, v));

  const decrement = () => {
    const newCount = validate(count - 1);

    setCount(newCount);

    if (parentOnChangeCallback) {
      parentOnChangeCallback(newCount);
    }
  };

  const increment = () => {
    const newCount = validate(count + 1);

    setCount(newCount);

    if (parentOnChangeCallback) {
      parentOnChangeCallback(newCount);
    }
  };

  const handleOnChange = ({ target }) => {
    const newCount = validate(target.value);

    setCount(newCount);

    if (parentOnChangeCallback) {
      parentOnChangeCallback(newCount);
    }
  };

  return (
    <NumberInputWrapper className="number-input" small={small} style={style}>
      {children && <span>{children}</span>}
      <NumberInputButton
        type="button"
        className="number-input__button"
        onClick={decrement}
        disabled={count <= min}
        small={small}
      >
        -
      </NumberInputButton>
      <input
        id={id}
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        value={count}
        onChange={handleOnChange}
        {...rest}
      />
      <NumberInputButton
        type="button"
        className="number-input__button"
        onClick={increment}
        disabled={count >= max}
        small={small}
      >
        +
      </NumberInputButton>
    </NumberInputWrapper>
  );
};

NumberInput.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  id: PropTypes.string,
  small: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  children: PropTypes.node,
};

NumberInput.defaultProps = {
  value: 1,
  min: 1,
  max: 9999,
  id: null,
  small: false,
  style: {},
  onChange: null,
  children: null,
};

export default NumberInput;
