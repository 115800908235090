import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ImageLogoId from '../../../assets/img/id_logo.png';
import MattressIcon from '../../../assets/img/MattressIcon';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { Configurators, shouldShowConfigurator } from '../../utils/misc';
import { connect } from '../../utils/redux';
import ExternalConfigurator from './ExternalConfigurator';

const SidebarEntryContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 15px 0;

  color: ${Colors.black};
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;

  z-index: 1;

  &:hover {
    color: #ffffff;

    .sidebar-entry--link {
      &::before {
        opacity: 1;
      }
    }

    .sidebar-entry--panel {
      opacity: 1;
      pointer-events: initial;
    }
  }
`;

const SidebarEntryLink = styled(({ isAdmin, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <NavLink {...rest} />
))`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 10px 13px;
  padding-bottom: 8px;
  margin-left: -13px;

  color: ${props => (props.isAdmin ? 'white' : 'currentColor')};
  text-decoration: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: ${props =>
      props.isAdmin ? Colors.lightGrey : Colors.primary};
    border-radius: 20px;

    opacity: 0;
    transition: opacity 0.2s ease;

    pointer-events: none;
    z-index: -1;
  }

  &.active {
    color: #ffffff;

    &::before {
      opacity: 1;
    }
  }
`;

const SidebarEntryPanel = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 100%;
  top: -15px;

  ${css`
    width: calc(
      ((${Sizing.wrapperWidth} - (${Sizing.gutterWidth} * 11)) / 12) * 9 +
        ${Sizing.gutterWidth} * 9
    );
  `};

  padding: 40px;
  padding-top: 34px;

  background-color: ${Colors.primary};

  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: -50px;
    width: 60px;
    height: 60px;

    background-color: #ffffff;
    border-radius: 50%;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 34px;
    margin-bottom: 38px;

    font-size: 20px;
    line-height: 24px;
    font-weight: 500;

    white-space: nowrap;

    .title {
      align-self: flex-start;
      line-height: 34px;
    }
  }
`;

const SidebarEntryPanelList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  list-style: none;
`;

const SidebarEntryPanelItem = styled.li`
  flex-basis: 25%;
  margin-bottom: 10px;
  padding: 5px;
  flex-basis: 25%;
`;

const SidebarEntryPanelPseudoLink = styled.button`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  font-size: 14px;
  text-decoration: none;
  color: ${Colors.black};
  background-color: ${Colors.white};
  box-shadow: 0 0 0 3px ${Colors.accent};
  border: none;
  border-radius: 3px;

  transition-property: color, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  cursor: pointer;

  &:hover,
  &:focus {
    color: ${Colors.white};
    background-color: ${Colors.accent};
  }

  img {
    width: 56px;
    height: auto;
    max-height: 56px;
    margin-bottom: 12px;

    object-fit: contain;
    object-position: center;

    pointer-events: none;
  }

  span {
    font-size: 16px;
    font-weight: 400;
  }
`;

const SidebarEntryPanelLink = styled(NavLink)`
  display: inline-block;
  padding: 10px 13px;

  text-decoration: none;
  color: currentColor;
  background-color: transparent;
  border-radius: 20px;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const NativeSidebarPanel = ({ header, aisle, entries }) => {
  const currentUser = useSelector(state => state.users.currentUser);

  return (
    <>
      <header>
        <span className="title">{header}</span>

        {shouldShowConfigurator('ontex', currentUser) && aisle.sCode === '01' && (
          <ExternalConfigurator
            isOntex
            fullscreen
            scrolling="yes"
            origin="ONTX"
          >
            <SidebarEntryPanelPseudoLink>
              <img src={ImageLogoId} alt="iD Expert" />
              <span>Partenaire iD</span>
            </SidebarEntryPanelPseudoLink>
          </ExternalConfigurator>
        )}

        {(
          appConfig.get('settings.connections.mattresses.aisles', []) || []
        ).includes(aisle.sCode) && (
          <ExternalConfigurator
            src={Configurators.PRESSURE_ULCER_PREVENTION}
            scrolling="no"
          >
            <SidebarEntryPanelPseudoLink>
              <MattressIcon size={56} color="currentColor" />
              <span>Configurateur</span>
            </SidebarEntryPanelPseudoLink>
          </ExternalConfigurator>
        )}
      </header>

      <SidebarEntryPanelList>
        {entries.map(({ key, to, label }) => (
          <SidebarEntryPanelItem key={key}>
            <SidebarEntryPanelLink to={to}>{label}</SidebarEntryPanelLink>
          </SidebarEntryPanelItem>
        ))}
      </SidebarEntryPanelList>
    </>
  );
};

NativeSidebarPanel.propTypes = {
  header: PropTypes.string.isRequired,
  aisle: PropTypes.objectOf(PropTypes.any).isRequired,
  entries: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export const SidebarPanel = connect(() => ({}), {})(NativeSidebarPanel);

const SidebarEntry = ({ to, label, isAdmin, children }) => {
  return (
    <SidebarEntryContainer>
      <SidebarEntryLink
        className="sidebar-entry--link"
        to={to}
        isAdmin={isAdmin ? 1 : 0}
      >
        {label}
      </SidebarEntryLink>
      {children && (
        <SidebarEntryPanel className="sidebar-entry--panel">
          {children}
        </SidebarEntryPanel>
      )}
    </SidebarEntryContainer>
  );
};

SidebarEntry.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  children: PropTypes.node,
};

SidebarEntry.defaultProps = {
  children: null,
  isAdmin: false,
};

export default SidebarEntry;
