import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { components as ReactSelectComponents } from 'react-select';
import styled from 'styled-components';

import ArrowLeftIcon from '../../../assets/img/ArrowLeftIcon';
import { PUBSUB_TOPICS, useSubscriber } from '../../hooks';
import {
  CART_ACTIONS,
  ORDER_ACTIONS,
  PREORDER_ACTIONS,
} from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import Button from './Button';
import CartList from './CartList';
import Confirm from './Confirm';
import HorizontalRule from './HorizontalRule';
import Input from './Input';
import { getMessage } from './ProductDelay';
import ProductPrice from './ProductPrice';
import Select from './Select';
import Switcher from './Switcher';

const CartCheckoutWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column-start: 9;
  grid-column-end: span 4;
  margin-bottom: 25px;
  padding: 30px;

  color: ${Colors.white};
  background-color: ${Colors.darkGrey};

  @media print {
    flex-direction: row-reverse;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: span 12;
    padding: 8px;
    margin-top: 0;

    color: ${Colors.black};
    background: ${Colors.white};
    border: 1px solid #ddd;
  }

  h4 {
    margin: 0;
    margin-bottom: 30px;

    color: inherit;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;

    @media print {
      display: none;
    }
  }

  .line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    @media print {
      justify-content: flex-end;
      margin-bottom: 0 !important;
    }

    .header,
    .product-price {
      display: block;
      flex-basis: 0;
      flex-grow: 1;

      @media print {
        flex-basis: unset;
        flex-grow: unset;
        width: unset;
      }
    }

    .header {
      margin-right: ${Sizing.gutterWidth};
      text-align: end;

      @media print {
        margin-right: 20px;
      }

      span {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;

        &.small {
          margin-left: 1ch;
          font-size: 10px;
          line-height: 12px;
        }

        @media print {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }

  .longest-delay {
    margin-bottom: 30px;

    @media print {
      margin-bottom: 0;
      flex-grow: 1;

      font-size: 14px;
      white-space: nowrap;
    }
  }
`;

const OrderComment = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 30px;

  color: ${Colors.white};
  background-color: ${Colors.lightGrey};
  border-radius: 20px;

  text-align: left;
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;

  @media print {
    margin: 20px 0;
    padding: 15px;

    border-radius: 10px;

    font-size: 14px;
    font-weight: normal;

    &::before {
      content: 'Commentaire de commande :';
      position: absolute;
      bottom: calc(100% + 5px);
      left: 15px;

      color: black;
    }
  }
`;

const InvalidProducts = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column-start: 9;
  grid-column-end: span 4;
  margin-bottom: 40px;
  padding: 10px 16px;

  background-color: white;
`;

const PreorderSelect = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 10px;

  label.select {
    width: 100%;

    .inline-select {
      width: 100%;

      .value,
      select {
        width: 100%;
      }
    }
  }
`;

const PreorderModeSelector = styled.div`
  width: 100%;
`;

const PreorderModeCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
`;

const PreorderModeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CartCheckoutOptionInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .left,
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left {
    justify-content: flex-start;
    overflow: hidden;

    .code {
      display: block;
      padding: 4px;
      margin-right: 8px;
      width: 8ch;
      flex-shrink: 0;

      color: ${({ isFocused }) => (isFocused ? Colors.black : Colors.darkGrey)};

      background-color: ${({ isFocused }) =>
        isFocused ? 'rgba(232, 94, 133, 0.3)' : Colors.white};

      border-radius: 30px;
      text-align: center;
      font-size: 9px;
    }

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .right {
    justify-content: flex-end;
    margin-left: 30px;
  }

  .date {
    font-size: 10px;
    color: ${Colors.lightGrey};
  }
`;

const CartCheckoutOption = props => {
  const { data, isFocused } = props;
  const { attributes } = data;
  const { /* code, */ title, date } = attributes;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactSelectComponents.Option {...props}>
      <CartCheckoutOptionInner isFocused={isFocused}>
        <div className="left">
          {/* <div className="code">{code}</div> */}
          <div className="title">{title}</div>
        </div>

        <div className="right date">{date}</div>
      </CartCheckoutOptionInner>
    </ReactSelectComponents.Option>
  );
};

CartCheckoutOption.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isFocused: PropTypes.bool,
};

CartCheckoutOption.defaultProps = {
  isFocused: false,
};

const CartCheckout = ({
  total,
  priceMode,
  isLoading,
  longestDelay,
  invalidProducts,
  isStatic,

  orderState,
  setOrderComment,
  sendCartAsOrder,
  sendCartAsPreorder,
  clearCart,
  preordersState,
  getPreorders,
  usersState,
}) => {
  const history = useHistory();

  const [preorderTitle, setPreorderTitle] = useState('');
  const [selectedPreorderID, setSelectedPreorderID] = useState(null);
  const [showPreorderModeContent, setShowPreorderModeContent] = useState(false);

  const { currentUser } = usersState || {};
  const { comment, status } = orderState || {};
  const orderStateLoading = status === Status.LOADING;

  const cleanComment = (comment || '').replace(/<br\s*\/?>/, '');

  useEffect(() => {
    if (
      !preordersState ||
      (preordersState.status === Status.IDLE &&
        !preordersState.loaded &&
        Object.keys(preordersState.preorders || {}).length === 0)
    ) {
      getPreorders();
    }
  }, [preordersState]);

  useSubscriber(PUBSUB_TOPICS.SEND_ORDER_SUCCESS, () => {
    history.push(Paths.OrderConfirmation());
  });

  useSubscriber(PUBSUB_TOPICS.SEND_PREORDER_SUCCESS, () => {
    clearCart();
    history.push(Paths.PreordersList({ preorderID: '' }));
  });

  const handleCartValidation = () => {
    if (invalidProducts && invalidProducts.length > 0) {
      Confirm({
        title: 'Avertissement',
        message: [
          'Votre panier contient des produits non référencés.',
          'Une commande séparée sera créée, et nous vous contacterons afin de déterminer la meilleure façon de satisfaire votre commande.',
        ],
        rejectButtonLabel: 'Annuler',
        acceptButtonLabel: 'OK',
        onConfirm: () => sendCartAsOrder({ comment: cleanComment }),
      })();
    } else {
      sendCartAsOrder({ comment: cleanComment });
    }
  };

  const handleCreatePreorder = () => {
    sendCartAsPreorder({
      preorderID: selectedPreorderID,
      title: preorderTitle,
      comment: cleanComment,
    });
  };

  const shouldShowCheckout =
    !isStatic && currentUser && !currentUser.isInternal;

  const preorders = [...Object.values(preordersState.preorders)].sort(
    (left, right) => {
      if (left.date.isBefore(right.date)) return 1;
      if (left.date.isAfter(right.date)) return -1;

      return 0;
    },
  );

  const preorderOptions = [
    ...preorders.map(({ code, title, formattedDate }) => ({
      value: code,
      label: `#${code} - ${title} (${formattedDate})`,
      attributes: { code, title, date: formattedDate },
    })),
  ];

  useEffect(() => {
    if (showPreorderModeContent) {
      if (selectedPreorderID) {
        const preorder = preorders.find(
          ({ code }) => code === selectedPreorderID,
        );

        if (preorder) {
          setPreorderTitle(preorder.title);
          setOrderComment(preorder.comment);
        }
      } else {
        setPreorderTitle('');
        setOrderComment('');
      }
    }
  }, [showPreorderModeContent, selectedPreorderID]);

  const renderPreorderSelector = color => (
    <PreorderModeSelector>
      {!showPreorderModeContent && (
        <PreorderModeCover>
          <Button
            onClick={() => {
              setSelectedPreorderID(null);
              setShowPreorderModeContent(true);
            }}
          >
            Créer une nouvelle précommande
          </Button>

          <HorizontalRule
            text="OU"
            background={color}
            style={{ margin: '30px 0' }}
          />

          <PreorderSelect>
            <Select
              components={{ Option: CartCheckoutOption }}
              placeholder="Modifier une précommande existante"
              options={preorderOptions}
              isSearchable
              noOptionsMessage={() => 'Aucune précommande trouvée'}
              onChange={({ value }) => {
                setSelectedPreorderID(value);
                setShowPreorderModeContent(true);
              }}
              styles={{
                control: provided => ({
                  ...provided,
                  background: Colors.lightGrey,
                  border: 'none',
                  borderRadius: '30px',
                  boxShadow: 'none',
                }),

                valueContainer: provided => ({
                  ...provided,
                  padding: '2px 2px 2px 14px',
                  color: Colors.white,
                }),

                placeholder: provided => ({
                  ...provided,
                  color: Colors.lighterGrey,
                }),

                input: provided => ({ ...provided, color: Colors.pureWhite }),

                dropdownIndicator: provided => ({
                  ...provided,
                  color: Colors.white,
                }),

                indicatorSeparator: () => ({ display: 'none' }),

                menu: provided => ({
                  ...provided,
                  width: 'unset',
                  minWidth: '100%',
                  left: 'unset',
                  right: 0,
                }),

                option: provided => ({ ...provided, color: Colors.black }),
              }}
            />
          </PreorderSelect>
        </PreorderModeCover>
      )}

      {showPreorderModeContent && (
        <PreorderModeContent>
          <Button
            background="transparent"
            color={Colors.white}
            style={{
              alignSelf: 'flex-start',
              marginBottom: '20px',
              padding: '6px 10px',
              minWidth: 'unset',
            }}
            onClick={() => setShowPreorderModeContent(false)}
          >
            <ArrowLeftIcon />
            <span>Retour</span>
          </Button>

          <Input
            type="text"
            placeholder="Titre de la précommande *"
            color={Colors.white}
            background={Colors.lightGrey}
            value={preorderTitle}
            onChange={setPreorderTitle}
            style={{ marginBottom: '12px' }}
          />

          <Input
            type="text"
            placeholder="Commentaire de précommande (facultatif)"
            color={Colors.white}
            background={Colors.lightGrey}
            textarea
            value={cleanComment}
            onChange={setOrderComment}
          />

          <Button
            fromComponent={Link}
            to="#"
            color={Colors.white}
            background={Colors.primary}
            style={{
              fontSize: '15px',
              lineHeight: '20px',
              fontWeight: 500,
              marginTop: '20px',
            }}
            disabled={orderStateLoading}
            onClick={handleCreatePreorder}
          >
            Enregistrer
          </Button>
        </PreorderModeContent>
      )}
    </PreorderModeSelector>
  );

  const renderCheckoutOptions = () => {
    if (currentUser.canPlaceOrders) {
      return (
        <Switcher tabs={['Commander', 'Précommander']}>
          <Switcher.Pane>
            <Input
              type="text"
              placeholder="Commentaire de commande (facultatif)"
              color={Colors.white}
              background={Colors.lightGrey}
              textarea
              value={cleanComment}
              onChange={setOrderComment}
            />

            <Button
              fromComponent={Link}
              to="#"
              color={Colors.white}
              background={Colors.primary}
              style={{
                fontSize: '15px',
                lineHeight: '20px',
                fontWeight: 500,
                marginTop: '20px',
              }}
              disabled={orderStateLoading}
              onClick={handleCartValidation}
            >
              Valider
            </Button>
          </Switcher.Pane>

          <Switcher.Pane>{renderPreorderSelector('#3E3E3E')}</Switcher.Pane>
        </Switcher>
      );
    }

    return renderPreorderSelector(Colors.darkGrey);
  };

  return (
    <>
      <CartCheckoutWrapper isStatic={!shouldShowCheckout}>
        <h4>Récapitulatif</h4>

        <div className="line">
          <div className="header">
            <span>Total</span>
            <span className="small">({priceMode})</span>
          </div>

          {isLoading ? (
            <span className="product-price placeholder">Chargement...</span>
          ) : (
            <ProductPrice value={total} size={20} align="left" />
          )}
        </div>

        {appConfig.get('settings.useProductDelay') && (
          <div className="longest-delay">
            {isLoading ? <span>Chargement...</span> : getMessage(longestDelay)}
          </div>
        )}

        {shouldShowCheckout && renderCheckoutOptions()}

        {!shouldShowCheckout && comment && (
          <OrderComment>{comment}</OrderComment>
        )}
      </CartCheckoutWrapper>

      {invalidProducts.length > 0 && (
        <InvalidProducts>
          <h3
            style={{
              marginBottom: 0,
              marginTop: 0,
              width: '100%',
              fontWeight: 'normal',
            }}
          >
            Articles non-référencés
          </h3>

          <h5 style={{ marginTop: '6px', fontWeight: 'normal', opacity: 0.75 }}>
            Cette liste contient des produits inconnus à l&apos;application.
            <br />
            Une commande séparée sera passée, et nous vous contacterons afin de
            déterminer la meilleure façon de satisfaire votre commande.
          </h5>

          <CartList products={invalidProducts} invalid />
        </InvalidProducts>
      )}
    </>
  );
};

CartCheckout.propTypes = {
  total: PropTypes.number,
  priceMode: PropTypes.oneOf(['HT', 'TTC']),
  isLoading: PropTypes.bool,
  longestDelay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ numeric: PropTypes.number }),
  ]),
  invalidProducts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isStatic: PropTypes.bool,

  usersState: PropTypes.objectOf(PropTypes.any),
  orderState: PropTypes.objectOf(PropTypes.any),

  setOrderComment: PropTypes.func.isRequired,
  sendCartAsOrder: PropTypes.func.isRequired,
  sendCartAsPreorder: PropTypes.func.isRequired,
  clearCart: PropTypes.func.isRequired,

  preordersState: PropTypes.objectOf(PropTypes.any),
  getPreorders: PropTypes.func.isRequired,
};

CartCheckout.defaultProps = {
  total: 0,
  priceMode: 'HT',
  isLoading: false,
  longestDelay: 0,
  invalidProducts: [],
  isStatic: false,

  usersState: {},
  orderState: {},
  preordersState: {},
};

export default connect(
  state => ({
    ...state.cart,
    usersState: state.users,
    orderState: state.order,
    preordersState: state.preorders,
  }),
  {
    ...CART_ACTIONS,
    ...ORDER_ACTIONS,
    ...PREORDER_ACTIONS,
  },
)(CartCheckout);
