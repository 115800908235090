import {
  CANCEL_GET_DISCOUNT_PRODUCTS,
  CLEAR_PRODUCTS,
  GET_DISCOUNT_PRODUCTS_REJECTED,
  GET_DISCOUNT_PRODUCTS_REQUESTED,
  GET_DISCOUNT_PRODUCTS_RESOLVED,
  GET_PRODUCTS_REJECTED,
  GET_PRODUCTS_REQUESTED,
  GET_PRODUCTS_RESOLVED,
  GET_SINGLE_PRODUCT_PDF_REJECTED,
  GET_SINGLE_PRODUCT_PDF_REQUESTED,
  GET_SINGLE_PRODUCT_PDF_RESOLVED,
  GET_SINGLE_PRODUCT_REJECTED,
  GET_SINGLE_PRODUCT_REQUESTED,
  GET_SINGLE_PRODUCT_RESOLVED,
  SEARCH_PRODUCTS_REJECTED,
  SEARCH_PRODUCTS_REQUESTED,
  SEARCH_PRODUCTS_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = {
  ...defaultState,
  meta: {},
  products: {},
  discounts: {},
  error: null,
  status: Status.IDLE,

  requests: [],
};

const initalProductState = {
  getPrice(quantity, defaultValue = {}) {
    return (this.priceData && this.priceData[quantity]) || defaultValue;
  },
};

export const filterPriceData = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((acc, key) => {
      acc[key] = obj[key];

      return acc;
    }, {});

export const mapProductData = (data, previousData = {}, quantity = 0) => {
  const {
    iTarifsVenteCount,
    iTarifsLocCount,
    sPrixHT,
    sPrixTTC,
    sBestPrixHT,
    sBestPrixTTC,
    sBestPrixQuantity,
    sPromoHT,
    sPromoTTC,
    sBestPromoHT,
    sBestPromoTTC,
    sBestPromoQuantity,
    ...rest
  } = data;

  const { pdfStatus, pdfFiles } = previousData || {};

  const previousPriceData = (previousData || {}).priceData || {};
  const previous = previousPriceData[quantity] || {};

  const current = filterPriceData(
    {
      iTarifsVenteCount,
      iTarifsLocCount,
      sPrixHT,
      sPrixTTC,
      sBestPrixHT,
      sBestPrixTTC,
      sBestPrixQuantity,
      sPromoHT,
      sPromoTTC,
      sBestPromoHT,
      sBestPromoTTC,
      sBestPromoQuantity,
    },
    value => !!value,
  );

  const next = {
    ...rest,

    sCode: `${rest.sCode}`,

    pdfStatus: pdfStatus || Status.IDLE,
    pdfFiles: pdfFiles || [],

    priceData: {
      ...previousPriceData,
      [quantity]: {
        ...previous,
        ...current,
      },
    },

    ...initalProductState,
  };

  return next;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUESTED:
    case SEARCH_PRODUCTS_REQUESTED: {
      const { __options } = action.params;

      if (__options?.clear) {
        return { ...initialState, status: Status.LOADING };
      }

      return { ...state, status: Status.LOADING, error: null };
    }

    case GET_SINGLE_PRODUCT_REQUESTED: {
      const { __options, sArticleCode } = action.params;

      if (__options?.clear) {
        return { ...initialState, status: Status.LOADING };
      }

      const previousData = state.products[sArticleCode] || {};
      const { pdfStatus, pdfFiles } = previousData;

      const newStatus = __options?.skipLoading
        ? Status.REFRESHING
        : Status.LOADING;

      // Keep PDF data
      const product = __options?.clearSelf
        ? {
            ...initalProductState,
            sCode: sArticleCode,
            status: newStatus,
            pdfStatus,
            pdfFiles,
          }
        : {
            ...initalProductState,
            ...previousData,
            sCode: sArticleCode,
            status: newStatus,
          };

      return {
        ...state,
        products: { ...state.products, [sArticleCode]: product },
        status: newStatus,
        error: null,
      };
    }

    case GET_PRODUCTS_RESOLVED:
    case SEARCH_PRODUCTS_RESOLVED:
    case GET_SINGLE_PRODUCT_RESOLVED: {
      const { data, params } = action.payload;
      const { sFamille, sQuantity } = params;
      const { recordArticles, ...meta } = data;

      const newProducts = recordArticles.reduce((acc, rec) => {
        const { sCode } = rec;

        const mappedRecord = mapProductData(
          rec,
          state.products[sCode],
          sQuantity,
        );

        mappedRecord.sFamille = mappedRecord.sFamille || sFamille;
        acc[sCode] = mappedRecord;

        return acc;
      }, {});

      const joinedProducts = {
        ...state.products,
        ...newProducts,
      };

      return {
        ...state,
        status: Status.IDLE,
        meta,
        products: joinedProducts,
        error: null,
      };
    }

    case GET_PRODUCTS_REJECTED:
    case SEARCH_PRODUCTS_REJECTED:
    case GET_DISCOUNT_PRODUCTS_REJECTED: {
      const { error, params } = action.payload;
      const { __request } = params;
      const { uuid } = __request;

      return {
        ...state,
        status: Status.ERROR,
        error,
        requests: state.requests.filter(req => req.uuid !== uuid),
      };
    }

    case GET_SINGLE_PRODUCT_REJECTED: {
      const { error, params } = action.payload;
      const { sArticleCode, __request, __options } = params;
      const { uuid } = __request || {};
      const { defaultName } = __options || {};

      return {
        ...state,
        products: {
          ...state.products,
          [sArticleCode]: {
            ...state.products[sArticleCode],
            sLibelle: state.products[sArticleCode]?.sLibelle || defaultName,
            status: Status.ERROR,
            error,
          },
        },
        status: Status.ERROR,
        error,
        requests: state.requests.filter(req => req.uuid !== uuid),
      };
    }

    case CLEAR_PRODUCTS: {
      return { ...state, products: {} };
    }

    case GET_DISCOUNT_PRODUCTS_REQUESTED: {
      const { __options, __request } = action.params;

      if (__options && __options.clear) {
        return {
          ...initialState,
          products: state.products,
          status: Status.LOADING,
          requests: [...state.requests, __request],
        };
      }

      return {
        ...state,
        status: Status.LOADING,
        error: null,
        requests: [...state.requests, __request],
      };
    }

    case GET_DISCOUNT_PRODUCTS_RESOLVED: {
      const { data, params } = action.payload;
      const { recordArticles, ...meta } = data;
      const { __request } = params;
      const { uuid } = __request;

      if (state.requests.some(req => req.uuid === uuid && req.cancelled)) {
        return { ...state };
      }

      return {
        ...state,
        discounts: recordArticles.map(product => mapProductData(product)),
        meta,
        status: Status.IDLE,
        error: null,
        requests: state.requests.filter(req => req.uuid !== uuid),
      };
    }

    case CANCEL_GET_DISCOUNT_PRODUCTS: {
      return {
        ...state,
        requests: state.requests.map(req => ({ ...req, cancelled: true })),
      };
    }

    case GET_SINGLE_PRODUCT_PDF_REQUESTED: {
      const { params } = action;
      const { sArticleCode } = params;
      const { products } = state;

      if (products[sArticleCode]) {
        products[sArticleCode].pdfStatus = Status.LOADING;
      }

      return { ...state, products: { ...products } };
    }

    case GET_SINGLE_PRODUCT_PDF_REJECTED: {
      const { payload } = action;
      const { params } = payload;
      const { sArticleCode } = params;
      const { products } = state;

      if (products[sArticleCode]) {
        products[sArticleCode].pdfStatus = Status.ERROR;
      }

      return { ...state, products: { ...products } };
    }

    case GET_SINGLE_PRODUCT_PDF_RESOLVED: {
      const { payload } = action;
      const { data, params } = payload;
      const { sArticleCode } = params;
      const { products } = state;

      const { Records } = data;

      if (products[sArticleCode]) {
        products[sArticleCode].pdfStatus = Status.IDLE;
        products[sArticleCode].pdfFiles = Records.map(
          ({ sDocTitle, sDateUpdate, B64 }) => ({
            base64: B64,
            title: sDocTitle,
            lastUpdated: sDateUpdate,
          }),
        );
      }

      return { ...state, products: { ...products } };
    }

    default:
      return state;
  }
};
