import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Link, NavLink, useHistory, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Logo from '../../../assets/img/Logo';
import LogoutIcon from '../../../assets/img/LogoutIcon';
import { USERS_ACTIONS } from '../../store/actions/reducer-actions/users';
import { Colors, Sizing } from '../../styles/vars';
import { shouldShowConfigurator } from '../../utils/misc';
import { connect } from '../../utils/redux';
import CartIndicator from '../elements/CartIndicator';
import renderAllConfigurators from '../elements/ConfiguratorButton';
import Dropdown from '../elements/Dropdown';
import HeaderCircle from '../elements/HeaderCircle';
import Paths from '../routes/paths';

const HeaderContainer = styled.header`
  position: ${props => (props.theme === 'dark' ? 'absolute' : 'relative')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 900;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    padding: 0 16px;
  }

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    position: fixed;
    top: 0;
    left: 0;
    height: ${Sizing.mobileHeaderHeight};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: ${Colors.darkGrey};

      pointer-events: none;
      z-index: 0;
    }
  }

  @media print {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;
  }
`;

const HeaderInner = styled.nav`
  position: relative;
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1800px;
  height: ${Sizing.headerHeight};
  padding: 0 32px;
  margin: 0 auto;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    height: ${Sizing.mobileHeaderHeight};
    padding: 0 16px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;

  &.left {
    flex-grow: 1;
    justify-content: flex-start;
    margin-left: 60px;

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      display: none;
    }
  }

  > *:not(:last-child) {
    margin-right: 34px;

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  .admin-links {
    margin-left: -10px;

    &:empty {
      display: none;
    }

    a {
      &:first-child {
        padding-left: 14px !important;

        &::before {
          border-top-left-radius: 40px !important;
          border-bottom-left-radius: 40px !important;
        }
      }

      &:last-child {
        padding-right: 14px !important;

        &::before {
          border-top-right-radius: 40px !important;
          border-bottom-right-radius: 40px !important;
        }
      }
    }
  }

  ${props =>
    props.hasMobileLayout &&
    css`
      @media screen and (max-width: ${Sizing.mobileWidth}) {
        position: absolute;
        flex-direction: column;
        top: 100%;
        left: -16px;
        width: 100vw;
        padding: 20px 16px;

        background-color: ${Colors.darkGrey};
        transform: translateY(${props.isOpen ? 0 : '-100%'});
        opacity: ${props.isOpen ? 1 : 0};
        pointer-events: ${props.isOpen ? 'initial' : 'none'};

        transition-property: transform, opacity;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;

        z-index: -1;

        * {
          color: ${Colors.white} !important;
        }
      }
    `}
`;

const HeaderHomeLink = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;

  svg {
    @media screen and (max-width: ${Sizing.mobileWidth}) {
      height: 22px;

      path {
        fill: ${Colors.white} !important;
      }
    }
  }

  span {
    margin-top: 5px;

    font-size: 12px;
    line-height: 15px;
    font-weight: normal;

    color: ${props => props.color};

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      color: ${Colors.white} !important;
    }
  }
`;

export const HeaderLink = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${Colors.black};
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;

  .header-circle {
    margin-left: 10px;
  }

  ${props =>
    props.theme === 'dark' &&
    css`
      color: ${Colors.white};
    `}
`;

export const HeaderButtonLink = styled(NavLink)`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;

  color: ${props => props.color || Colors.white};
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;

  z-index: 1;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    color: ${Colors.white} !important;
  }

  &.with-header-circle > span:first-of-type {
    margin-right: 8px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 4px);

    background-color: ${props => props.backgroundColor || Colors.primary};
    border-radius: 40px;
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.5);

    z-index: -1;
  }

  ${props =>
    props.theme === 'dark' &&
    css`
      @media screen and (min-width: ${Sizing.mobileWidth}) {
        color: ${Colors.black};

        &::before {
          background-color: ${Colors.white};
          box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.25);
        }
      }
    `}

  &[data-admin] {
    padding-left: 8px;
    padding-right: 8px;
    color: ${props =>
      props.theme === 'dark' ? Colors.white : Colors.black} !important;

    &::before {
      left: 0;
      width: 100%;

      background-color: ${Colors.accent};
      border-radius: 0;
      box-shadow: none;
      opacity: 0.2;
    }

    &[aria-current='page'] {
      &::before {
        opacity: 1;
      }
    }
  }

  &:not([data-admin]) {
    &[aria-current='page'] {
      &:hover,
      &:focus {
        color: ${props => props.color || Colors.white};
      }

      .header-circle {
        border-color: ${props => props.backgroundColor || Colors.primary};
      }
    }

    &:not([aria-current='page']) {
      color: ${props => (props.theme === 'dark' ? Colors.white : Colors.black)};

      &::before {
        content: unset;
      }
    }
  }

  @media print {
    display: none;
  }
`;

export const HeaderButtonExternalLink = styled.a`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;

  color: ${props => props.color || Colors.white};
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;

  z-index: 1;

  &.with-header-circle > span:first-of-type {
    margin-right: 8px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 4px);

    background-color: ${props => props.backgroundColor || Colors.primary};
    border-radius: 40px;
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.5);

    z-index: -1;
  }

  ${props =>
    props.theme === 'dark' &&
    css`
      color: ${Colors.black};

      &::before {
        background-color: ${Colors.white};
        box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.25);
      }
    `}

  &[data-admin] {
    padding-left: 8px;
    padding-right: 8px;
    color: ${props =>
      props.theme === 'dark' ? Colors.white : Colors.black} !important;

    &::before {
      left: 0;
      width: 100%;

      background-color: ${Colors.accent};
      border-radius: 0;
      box-shadow: none;
      opacity: 0.2;
    }

    &[aria-current='page'] {
      &::before {
        opacity: 1;
      }
    }
  }

  &:not([data-admin]) {
    &[aria-current='page'] {
      .header-circle {
        border-color: ${props => props.backgroundColor || Colors.primary};
      }
    }

    &:not([aria-current='page']) {
      color: ${props => (props.theme === 'dark' ? Colors.white : Colors.black)};

      &::before {
        content: unset;
      }
    }
  }

  &.with-icon {
    img {
      height: auto;
      max-height: 16px;
      width: 32px;
      margin-right: 8px;

      object-fit: contain;
      object-position: center;
    }
  }

  @media print {
    display: none;
  }
`;

export const HeaderButtonExternalConfigurator = styled(
  HeaderButtonExternalLink,
)`
  justify-content: flex-start;
  width: 100%;
  padding: 10px 16px;

  color: ${Colors.black} !important;

  &:hover,
  &:focus-visible {
    background-color: ${Colors.white};
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .username {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 8px;

    font-size: 10px;
    color: ${props => (props.theme === 'dark' ? Colors.white : Colors.black)};

    .label {
      font-size: 8px;
      opacity: 0.75;
      letter-spacing: 0.25px;
      font-weight: 300;
    }

    .name {
      &.customer-name {
        display: block;
        position: relative;
        margin-top: 2px;
        max-width: 150px;

        text-align: right;
        font-size: 0.85em;
      }
    }
  }
`;

const HeaderSeparator = styled.div`
  display: block;
  position: relative;
  width: 1px;
  align-self: stretch;
  margin-right: 28px !important;

  background-color: ${Colors.black};
  opacity: 0.2;

  @media print {
    display: none;
  }

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    display: none;
  }
`;

const HeaderMobileMenuToggle = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;

  background: none;
  border: none;
  outline: none;

  @media screen and (min-width: ${Sizing.mobileWidth}) {
    display: none !important;
  }

  span {
    position: relative;
    display: block;
    width: calc(100% - 10px);
    height: 1px;

    color: ${Colors.white};
    background-color: currentColor;

    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    pointer-events: none;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;

      background-color: currentColor;

      transform-origin: center;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }

    &::before {
      transform: translateY(-6px) rotate(0);
    }

    &::after {
      transform: translateY(6px) rotate(0);
    }

    ${props =>
      props.isOpen &&
      css`
        background-color: transparent;

        &::before {
          transform: translateY(0) rotate(25deg);
        }

        &::after {
          transform: translateY(0) rotate(-25deg);
        }
      `}
  }
`;

const HeaderMobileCloseButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  display: block;
  position: absolute;
  top: 0;
  left: -16px;
  width: calc(100% + 32px);
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: none;
  outline: none;

  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  pointer-events: ${props => (props.isOpen ? 'initial' : 'none')};
  z-index: -1;

  @media screen and (min-width: ${Sizing.mobileWidth}) {
    display: none !important;
  }
`;

const Header = ({ match, currentUser, logoutUser }) => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const { path } = match;

  const isDark = [
    Paths.Home(),
    Paths.Login(),
    Paths.RequestPassword(),
    Paths.ResetPassword(),
    Paths.AdminIndex(),
    Paths.Admin(),
  ].includes(path)
    ? 1
    : 0;

  const hasUser = !!currentUser;
  const isLogin = path === Paths.Login();

  const theme = isDark ? 'dark' : 'regular';
  const shade = isDark ? 'rgba(0, 0, 0, 0.25)' : 'rgba(255, 255, 255, 0.5)';

  const configuratorPreconditions = [
    shouldShowConfigurator('ontex', currentUser),
    shouldShowConfigurator('hartmann', currentUser),
    shouldShowConfigurator('dasri', currentUser),
    shouldShowConfigurator('amd', currentUser),
  ];

  const configuratorCount = configuratorPreconditions.filter(
    precond => !!precond,
  ).length;

  const hasConfigurators =
    hasUser && configuratorPreconditions.some(precond => !!precond);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';

    return () => (document.body.style.overflow = '');
  }, [isOpen]);

  // Unused for now
  const renderLeftLinks = () => {
    if (!hasUser) {
      return null;
    }

    return null;
  };

  const renderConfigurators = () => {
    if (!hasConfigurators) {
      return null;
    }

    if (configuratorCount === 1) {
      return renderAllConfigurators(currentUser, HeaderButtonExternalLink, {
        theme,
      });
    }

    return (
      <Dropdown title="Services externes" theme={theme}>
        {renderAllConfigurators(currentUser, HeaderButtonExternalConfigurator, {
          theme,
        })}
      </Dropdown>
    );
  };

  const renderRightLinks = () => {
    if (!hasUser) {
      if (isLogin) return null;

      return (
        <HeaderButtonLink to={Paths.Login()} theme={theme}>
          <span>Connexion</span>
        </HeaderButtonLink>
      );
    }

    return (
      <HeaderSection className="right" hasMobileLayout isOpen={isOpen}>
        <HeaderButtonLink to={Paths.Shop()} theme={theme}>
          <span>Produits</span>
        </HeaderButtonLink>

        <HeaderButtonLink to={Paths.Contact()} theme={theme}>
          <span>Contact</span>
        </HeaderButtonLink>

        <HeaderButtonLink to={Paths.OrdersList({ orderID: '' })} theme={theme}>
          <span>Mes commandes</span>
        </HeaderButtonLink>

        <HeaderButtonLink
          to={Paths.PreordersList({ preorderID: '' })}
          theme={theme}
        >
          <span>Mes pré-commandes</span>
        </HeaderButtonLink>

        <CartIndicator theme={theme} shade={shade} />

        {(currentUser.manager || currentUser.admin) && <HeaderSeparator />}

        <div className="admin-links">
          {currentUser.admin && (
            <HeaderButtonLink
              to={Paths.AdminIndex()}
              theme={theme}
              data-admin="first"
            >
              <span>Administration</span>
            </HeaderButtonLink>
          )}

          {shouldShowConfigurator('dhx', currentUser) && (
            <HeaderButtonExternalLink
              href={currentUser.autologinURL}
              target="_blank"
              theme={theme}
              data-admin="last"
            >
              <span>Accès DHX</span>
            </HeaderButtonExternalLink>
          )}
        </div>

        {renderConfigurators()}

        <UserInfo theme={theme}>
          <div className="username">
            <div className="label">Connecté en tant que</div>
            <div className="name">{currentUser.fullName}</div>

            {hasUser && currentUser.customerName && (
              <div className="name customer-name">
                {currentUser.customerName}
              </div>
            )}
          </div>

          <HeaderCircle
            color={Colors.primary}
            shade={shade}
            onClick={() => {
              history.push(Paths.Home());
              logoutUser();
            }}
          >
            <LogoutIcon />
          </HeaderCircle>
        </UserInfo>
      </HeaderSection>
    );
  };

  return (
    <HeaderContainer theme={theme}>
      <HeaderInner>
        <HeaderHomeLink
          to={Paths.Home()}
          title="Accueil"
          color={isDark ? 'white' : Colors.accent}
        >
          <Logo height={30} color={isDark ? 'white' : Colors.accent} />
        </HeaderHomeLink>

        {renderLeftLinks()}

        {hasUser && (
          <>
            <HeaderMobileMenuToggle
              isOpen={isOpen}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="bar" />
            </HeaderMobileMenuToggle>

            <HeaderMobileCloseButton
              isOpen={isOpen}
              onClick={() => setIsOpen(false)}
            />
          </>
        )}

        {renderRightLinks()}

        {/* {hasUser && currentUser.customerName && (
          <div className="name customer-name absolute-right">
            {currentUser.customerName}
          </div>
        )} */}
      </HeaderInner>
    </HeaderContainer>
  );
};

Header.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any),
  logoutUser: PropTypes.func.isRequired,
};

Header.defaultProps = {
  currentUser: null,
};

export default connect(() => ({}), { ...USERS_ACTIONS })(
  memo(withRouter(Header)),
);
