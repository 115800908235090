import { Empty } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CART_ACTIONS, FAMILIES_ACTIONS } from '../../store/actions';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import CartItemComment from './CartItemComment';
import CartListItemLocationSelector from './CartListItemLocationSelector';
import NumberInput from './NumberInput';
import ProductPrice, { PriceMode } from './price/ProductPrice';
import ProductDelay from './ProductDelay';
import ResourceImage from './ResourceImage';

const CartListContainer = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-column: 1 / span ${props => (props.$empty ? 12 : 8)};
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;

  list-style: none;

  ${props =>
    props.compact &&
    css`
      @media print {
        grid-column: 1 / span 12;
        margin-bottom: 10px;
      }
    `}

  ${props =>
    props.invalid &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px 0;
      align-items: flex-start;
      margin-bottom: 0;
    `}

    ${props =>
      props.invalid &&
      props.compact &&
      css`
        margin-bottom: 40px;
        padding: 12px;

        background-color: white;
      `}

  @media print {
    margin-bottom: 0;
  }
`;

const CartListItem = styled.li`
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 0 ${Sizing.gutterWidth};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.compact ? '20px' : '40px 60px')};
  margin-bottom: ${props => (props.compact ? '10px' : '20px')};

  background-color: white;

  &:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.invalid &&
    css`
      display: block;
      padding: 0;
      margin-bottom: 0;

      &:not(:last-child)::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        width: 100%;
        height: 1px;

        background-color: ${Colors.white};

        pointer-events: none;
      }
    `}

  @media print {
    padding: 4px 8px !important;
    margin-bottom: 0 !important;
    border: 1px solid #ddd;
    border-bottom: none;
  }
`;

const CartListItemInner = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: ${Sizing.gutterWidth};
  grid-column-start: ${props => (props.compact ? 1 : 1)};
  grid-column-end: span ${props => (props.compact ? 8 : 8)};

  ${props =>
    props.compact &&
    css`
      @media print {
        grid-column-start: 1;
        grid-column-end: span 8;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
      }
    `}

  ${props =>
    props.invalid &&
    css`
      grid-template-columns: 1fr auto;
      gap: 0 10px;
    `}
`;

const CartItemSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  grid-column: ${props => props.start || 1} / span ${props => props.span || 1};

  ${props =>
    props.square &&
    css`
      &::before {
        content: '';
        display: block;
        position: relative;
        top: 0;
        left: 0;
        padding-bottom: 100%;
      }
    `}

  ${props =>
    props.square &&
    props.compact &&
    css`
      align-self: center;

      @media print {
        margin-right: 16px;
      }
    `}

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media print {
    flex-basis: 0;
    flex-grow: ${props => (props.square ? 1 : 4)};

    ${props =>
      props.hideOnPrint &&
      css`
        display: none !important;
      `}
  }
`;

const CartItemDescription = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .product-title {
    position: relative;
    width: 100%;
    margin: 0;
    margin-bottom: 16px;

    color: currentColor;
    font-weight: bold;
    text-decoration: none;

    .below-comment {
      display: block;
      margin-top: 6px;

      white-space: pre-line;
      font-size: 14px;
      font-weight: normal;

      &.is-invalid {
        color: ${Colors.darkYellow};
        font-weight: bold;
      }

      &:not(.title) {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    @media print {
      margin-bottom: 4px;

      font-size: 14px;
      font-weight: 500;

      &:not(.dummy) {
        margin-bottom: 0;
      }

      .below-comment {
        margin-top: 0;

        color: ${Colors.darkGrey};
        font-size: 11px;

        &::before {
          content: 'Commentaire: ';
        }
      }
    }
  }
`;

const CartItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${props =>
    props.margin &&
    css`
      margin-bottom: 12px;
    `}

  .prices {
    .price-unit {
      display: block;
      margin-bottom: 0;

      .number,
      .currency {
        display: inline-block;
      }

      .currency {
        margin-left: 5px;
      }

      .quantity {
        position: relative;
        display: block;
      }
    }
  }

  @media print {
    margin-bottom: 0 !important;
  }
`;

const PriceAdditionalLabel = styled.span`
  display: inline-block;
  margin-left: 10px;
  min-width: 45px;

  font-size: 8px;
  line-height: 9px;
  color: ${Colors.darkGrey};
`;

const CartItemBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const CartItemCountLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;

  .number-input {
    background-color: ${Colors.white};

    .number-input__button {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

const CartItemRemoveButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  padding: 0;
  font-size: 10px;
  line-height: 12px;
  text-decoration: underline;
  background: none;
  border: none;

  white-space: nowrap;

  cursor: pointer;
`;

const CompactQuantity = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: 14px;
  color: ${Colors.lightGrey};
  font-weight: 500;
  white-space: nowrap;

  @media print {
    align-self: flex-end;
  }
`;

const SpecificQuantities = styled.div`
  flex-grow: 1;
  max-width: 300px;
  margin-right: 30px;

  .title {
    font-weight: bold;
    margin-bottom: 6px;
  }

  ul.quantities-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;

    list-style: none;

    li {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      padding: 6px 4px;

      background-color: ${Colors.white};
      border: 1px solid rgba(178, 178, 178, 0.3);

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      & + li {
        margin-left: -1px;
      }

      .label {
        margin-bottom: 6px;

        color: ${Colors.darkGrey};
        font-size: 12px;
        text-align: center;
      }

      .value {
        font-size: 22px;
      }
    }
  }
`;

const InvalidCartList = ({ products, removeFromCart, isStatic, compact }) => {
  return (
    <CartListContainer invalid isStatic={isStatic} compact={compact}>
      {products.map(product => {
        const { id, sCode, sLibelle, count } = product;
        const code = id || sCode;

        return (
          <CartListItem key={code} compact invalid>
            <CartListItemInner compact invalid>
              {sLibelle && (
                <CartItemSection
                  start={1}
                  span={1}
                  style={{ fontSize: '14px' }}
                  compact
                  invalid
                  hideOnPrint
                >
                  {sLibelle}
                </CartItemSection>
              )}

              <CartItemSection
                start={2}
                span={1}
                compact
                invalid
                style={{ alignItems: 'flex-end', fontSize: '12px' }}
              >
                <span>Quantité : {count}</span>
              </CartItemSection>

              <CartItemSection
                start={1}
                span={1}
                style={{ fontSize: '13px' }}
                compact
                invalid
              >
                Code produit : {code}
              </CartItemSection>

              {!isStatic && (
                <CartItemSection
                  start={2}
                  span={1}
                  compact
                  invalid
                  style={{ alignItems: 'flex-end' }}
                >
                  <CartItemRemoveButton
                    type="button"
                    onClick={() => removeFromCart(product, { invalid: true })}
                    invalid
                  >
                    Supprimer
                  </CartItemRemoveButton>
                </CartItemSection>
              )}
            </CartListItemInner>
          </CartListItem>
        );
      })}
    </CartListContainer>
  );
};

InvalidCartList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  removeFromCart: PropTypes.func.isRequired,
  isStatic: PropTypes.bool,
  compact: PropTypes.bool,
};

InvalidCartList.defaultProps = {
  products: [],
  isStatic: false,
  compact: false,
};

const CartList = ({
  products,
  handleCountChangeFor,
  handlePropagateState,
  compact,
  isStatic,
  invalid,

  familiesState,
  usersState,

  removeFromCart,
}) => {
  if (invalid) {
    return (
      <InvalidCartList
        products={products}
        removeFromCart={removeFromCart}
        invalid={invalid}
        isStatic={isStatic}
        compact={compact}
      />
    );
  }

  const isCartEmpty = !products || !products.length;

  const sortedProducts = (products || []).sort((a, b) => b.stamp - a.stamp);

  const { currentUser } = usersState;
  const { priceMode } = currentUser || { priceMode: 'HT' };

  const renderProductSpecificQuantities = quantities => {
    const labelsMap = {
      cartons: { singular: 'Carton', plural: 'Cartons' },
      boxes: { singular: 'Boîte / Sachet', plural: 'Boîtes / Sachets' },
      units: { singular: 'Unité', plural: 'Unités' },
    };

    const entries = Object.entries(quantities)
      .map(([name, value]) => {
        const intValue = Number.parseInt(value, 10) || 0;

        const { singular, plural } = labelsMap[name];
        const label = intValue === 1 ? singular : plural;

        return { name, label, value: intValue };
      })
      .sort((a, b) => a.value - b.value);

    return (
      <SpecificQuantities>
        <div className="title">Quantités</div>

        <ul className="quantities-list">
          {entries.map(({ name, label, value }) => (
            <li key={name}>
              <span className="value">{value}</span>
              <span
                className="label"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: label }}
              />
            </li>
          ))}
        </ul>
      </SpecificQuantities>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderProductQuantity = ({ product, count }) => {
    // eslint-disable-next-line react/prop-types
    const { sCode, hasSpecificQuantities, quantities } = product;

    if (hasSpecificQuantities) {
      return renderProductSpecificQuantities(quantities);
    }

    return (
      <CartItemCountLabel htmlFor={`#cart-count-${sCode}`}>
        <NumberInput
          value={count}
          id={`cart-count-${sCode}`}
          min={1}
          max={9999}
          onChange={handleCountChangeFor(product)}
        >
          Quantité
        </NumberInput>
      </CartItemCountLabel>
    );
  };

  return (
    <CartListContainer compact={compact} $empty={isCartEmpty}>
      {isCartEmpty ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span style={{ color: Colors.black }}>Votre panier est vide.</span>
          }
        />
      ) : (
        sortedProducts.map(product => {
          /* eslint-disable react/prop-types */
          const {
            primaryKey,
            sCode,
            sLibelle,
            count,
            sBelowComment,
            isDummy,
            sFamille,
          } = product;
          /* eslint-enable react/prop-types */

          const productFamily = (familiesState.families || {})[sFamille];

          const rowID = [primaryKey, sCode, sBelowComment]
            .map(s => `${s}`)
            .join('|-|');

          /* XXX: Add || true to enable for anyone (testing) */
          const showAssignLocation =
            !isDummy &&
            !isStatic &&
            currentUser?.canUseCartPreLocalization &&
            productFamily?.isLocalizable;

          const renderDelay = () => {
            if (isDummy) return null;

            if (appConfig.get('settings.useProductDelay')) {
              if (isStatic) {
                return (
                  <ProductDelay
                    product={product}
                    quantity={count}
                    propagateState={handlePropagateState}
                    headless
                  />
                );
              }

              return (
                <CartItemRow>
                  <ProductDelay
                    product={product}
                    quantity={count}
                    propagateState={handlePropagateState}
                    leftAlign
                  />
                </CartItemRow>
              );
            }

            return <div className="delay dummy" />;
          };

          const renderBelowComment = () => {
            const isInvalid =
              sBelowComment &&
              // eslint-disable-next-line react/prop-types
              sBelowComment.match(
                /^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i,
              );

            if (isInvalid) {
              return (
                <span
                  className={classNames({
                    'below-comment': true,
                    title: true,
                    'is-invalid': isInvalid,
                  })}
                >
                  {sBelowComment}
                </span>
              );
            }

            if (isStatic) {
              return <div className="below-comment title">{sBelowComment}</div>;
            }

            if (appConfig.get('settings.useCartItemComment')) {
              return <CartItemComment product={product} />;
            }

            return null;
          };

          return (
            <CartListItem key={rowID} compact={compact}>
              <CartListItemInner compact={compact}>
                <CartItemSection
                  start={1}
                  span={compact ? 1 : 2}
                  compact={compact}
                  square
                  hideOnPrint
                >
                  <ResourceImage code={sCode} />
                </CartItemSection>

                <CartItemSection start={compact ? 2 : 3} span={compact ? 5 : 4}>
                  <CartItemDescription>
                    {isDummy ? (
                      <div className="product-title dummy">
                        {sLibelle && <span className="title">{sLibelle}</span>}

                        {showAssignLocation && (
                          <CartListItemLocationSelector itemID={sCode} />
                        )}

                        {renderBelowComment()}
                      </div>
                    ) : (
                      <div className="product-title dummy">
                        <Link
                          to={Paths.SingleProduct({ productID: sCode })}
                          className="product-title"
                        >
                          {sLibelle && (
                            <span className="title">{sLibelle}</span>
                          )}
                        </Link>

                        {showAssignLocation && (
                          <CartListItemLocationSelector itemID={sCode} />
                        )}

                        {renderBelowComment()}
                      </div>
                    )}

                    {renderDelay()}

                    <CartItemRow margin>
                      {isStatic && (
                        <CompactQuantity>Quantité : {count}</CompactQuantity>
                      )}

                      {!isDummy && (
                        <ProductPrice
                          product={product}
                          count={count}
                          mode={PriceMode.CART}
                        >
                          <PriceAdditionalLabel className="ht">
                            prix total {priceMode}
                          </PriceAdditionalLabel>
                        </ProductPrice>
                      )}
                    </CartItemRow>
                  </CartItemDescription>

                  {/* Cart row footer with quantity */}
                  <CartItemBottom>
                    {isStatic ? null : (
                      <>
                        {renderProductQuantity({ product, isDummy, count })}

                        <CartItemRemoveButton
                          type="button"
                          onClick={() => removeFromCart(product)}
                        >
                          Supprimer du panier
                        </CartItemRemoveButton>
                      </>
                    )}
                  </CartItemBottom>
                </CartItemSection>
              </CartListItemInner>
            </CartListItem>
          );
        })
      )}
    </CartListContainer>
  );
};

CartList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({ sBelowComment: PropTypes.string }),
  ).isRequired,
  handleCountChangeFor: PropTypes.func,
  handlePropagateState: PropTypes.func,
  compact: PropTypes.bool,
  isStatic: PropTypes.bool,
  invalid: PropTypes.bool,

  familiesState: PropTypes.objectOf(PropTypes.any).isRequired,

  usersState: PropTypes.shape({
    currentUser: PropTypes.objectOf(PropTypes.any),
  }).isRequired,

  removeFromCart: PropTypes.func.isRequired,
};

CartList.defaultProps = {
  handleCountChangeFor: () => {},
  handlePropagateState: () => {},
  compact: false,
  isStatic: false,
  invalid: false,
};

export default connect(
  state => ({
    ...state.cart,
    familiesState: state.families,
    usersState: state.users,
  }),
  {
    ...CART_ACTIONS,
    ...FAMILIES_ACTIONS,
  },
)(CartList);
