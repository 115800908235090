import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ArrowCornerUpLeft from '../../../assets/img/ArrowCornerUpLeft';
import TrashIcon from '../../../assets/img/TrashIcon';
import { usePageTitle } from '../../hooks';
import {
  CART_ACTIONS,
  ORDER_ACTIONS,
  PRODUCT_ACTIONS,
} from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import { parsePriceString } from '../../utils/misc';
import { connect } from '../../utils/redux';
import Button from '../elements/Button';
import CartCheckout from '../elements/CartCheckout';
import CartList from '../elements/CartList';
import Confirm from '../elements/Confirm';
import PageTitle from '../elements/PageTitle';
import ScrollToTop from '../utils/ScrollToTop';
import Paths from './paths';

const UndoDisclaimerContainer = styled.p`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 10px 20px;

  background-color: white;

  button {
    min-width: unset;
    padding: 6px 14px;
  }
`;

const CartLists = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-column: 1 / span ${props => (props.$empty ? 12 : 8)};
  grid-row-end: span 3;
  padding: 0;
  margin: 0;
`;

const UndoDisclaimer = ({ past, undoAction }) => {
  if (past) {
    const { payload } = past;
    const { product } = payload;
    const { sLibelle, id } = product;

    return (
      <UndoDisclaimerContainer>
        <span>
          {sLibelle ? `${sLibelle} supprimé(e).` : `Produit n°${id} supprimé.`}
        </span>

        <Button
          type="button"
          background={Colors.white}
          hoverBackground={Colors.accent}
          onClick={() => undoAction()}
        >
          <ArrowCornerUpLeft />
          <span>Annuler</span>
        </Button>
      </UndoDisclaimerContainer>
    );
  }

  return null;
};

UndoDisclaimer.propTypes = {
  past: PropTypes.objectOf(PropTypes.any),
  undoAction: PropTypes.func.isRequired,
};

UndoDisclaimer.defaultProps = {
  past: null,
};

const Cart = ({
  past,
  present,
  usersState,
  setCartItemCount,
  undoCartAction,
  clearCart,
  clearCartHistory,
  fetchSingleProduct,
}) => {
  const { products, invalidProducts } = present || {
    products: {},
    invalidProducts: {},
  };

  const productsArr = Object.values(products || {});
  const invalidProductsArr = Object.values(invalidProducts || {});

  const [itemDelays, setItemDelays] = useState(
    productsArr.reduce((acc, prod) => {
      const { sCode } = prod;

      acc[sCode] = { count: 0, loading: false };

      return acc;
    }, {}),
  );

  const isLoading =
    Object.values(itemDelays).some(d => d.loading) ||
    productsArr.some(
      product =>
        !product.priceData[product.count] ||
        [Status.LOADING, Status.REFRESHING].includes(product?.status),
    );

  const longestDelay = Object.values(itemDelays).reduce(
    (acc, d) =>
      Math.max(
        acc,
        Number.parseInt(
          typeof d.count === 'number' ? d.count : d.count?.numeric,
          10,
        ) || 0,
      ),
    0,
  );

  const { currentUser } = usersState;
  const { priceMode } = currentUser;

  const getProductPriceByMode = (product, count) => {
    switch (priceMode) {
      case 'TTC': {
        const {
          sPromoTTC,
          sBestPromoQuantity,
          sBestPromoTTC,
          sBestPrixQuantity,
          sBestPrixTTC,
          sPrixTTC,
        } = product.getPrice(count);

        return {
          promo: sPromoTTC,
          bestPromoQuantity: sBestPromoQuantity,
          bestPromo: sBestPromoTTC,
          bestPrixQuantity: sBestPrixQuantity,
          bestPrix: sBestPrixTTC,
          prix: sPrixTTC,
        };
      }

      case 'HT':
      default: {
        const {
          sPromoHT,
          sBestPromoQuantity,
          sBestPromoHT,
          sBestPrixQuantity,
          sBestPrixHT,
          sPrixHT,
        } = product.getPrice(count);

        return {
          promo: sPromoHT,
          bestPromoQuantity: sBestPromoQuantity,
          bestPromo: sBestPromoHT,
          bestPrixQuantity: sBestPrixQuantity,
          bestPrix: sBestPrixHT,
          prix: sPrixHT,
        };
      }
    }
  };

  const totalCount = productsArr.reduce((acc, prod) => acc + prod.count, 0);

  const total = productsArr.reduce((acc, product) => {
    const { count } = product;
    const {
      promo,
      bestPromoQuantity,
      bestPromo,
      bestPrixQuantity,
      bestPrix,
      prix,
    } = getProductPriceByMode(product, count);

    let value = 0;

    if (promo) {
      if (count >= bestPromoQuantity) {
        value = (parsePriceString(bestPromo) || 0) * count;
      } else {
        value = (parsePriceString(promo) || 0) * count;
      }
    } else if (count >= bestPrixQuantity) {
      value = (parsePriceString(bestPrix) || 0) * count;
    } else {
      value = (parsePriceString(prix) || 0) * count;
    }

    return acc + value;
  }, 0);

  usePageTitle(totalCount ? `Panier [${totalCount}]` : 'Panier');

  useEffect(() => {
    console.info('🔁 Recalculating all missing product prices');

    Object.values(products).forEach(product => {
      const { sCode, count } = product;
      const { sPrixHT } = product.getPrice(count) || {};

      console.debug(`>>> ${product.sCode} @ ${count} => ${sPrixHT}`);

      if (!sPrixHT && sPrixHT !== 0) {
        fetchSingleProduct({ sArticleCode: sCode, sQuantity: count });
      }
    });

    // XXX: Was empty deps, now recompute whenever products are added or removed
  }, [products]);

  const handleCountChangeFor = product => nextValue => {
    const count = Number.parseInt(nextValue || 1, 10);

    console.info(
      `🔂 Recalculating single product price (${product.sCode}) after quantity change`,
    );

    setCartItemCount({ product, count, skipSort: true });
    fetchSingleProduct({
      sArticleCode: product.sCode,
      sQuantity: count,
      skipSort: true,
    });
  };

  const handlePropagateState = ({ product, delay, status }) => {
    const { sCode } = product;

    setItemDelays({
      ...itemDelays,
      [sCode]: {
        count: delay,
        loading: status === Status.LOADING,
      },
    });
  };

  useEffect(() => () => clearCartHistory(), []);

  const handleOnClearCartRequested = event => {
    event.preventDefault();
    event.stopPropagation();

    Confirm({
      title: 'Vider le panier',
      message: [
        'Êtes-vous sur(e) de vouloir vider votre panier ?',
        'Son contenu sera perdu.',
      ],
      onConfirm: () => clearCart(),
    })();
  };

  const isCartEmpty = !productsArr || productsArr.length === 0;

  const shouldShowCheckout =
    productsArr &&
    (productsArr.length > 0 ||
      (invalidProductsArr && invalidProductsArr.length > 0));

  return (
    <>
      <ScrollToTop />

      <PageTitle
        fromComponent="h2"
        additionalContent={
          productsArr &&
          productsArr.length > 0 && (
            <Button
              type="button"
              color={Colors.white}
              background={Colors.primary}
              hoverColor={Colors.pureWhite}
              hoverBackground={Colors.red}
              style={{
                minWidth: 'unset',
                padding: '10px 14px',
                marginLeft: 'auto',
              }}
              onClick={handleOnClearCartRequested}
            >
              <TrashIcon />
              <span>Vider le panier</span>
            </Button>
          )
        }
      >
        Panier
      </PageTitle>

      <CartLists $empty={isCartEmpty && !shouldShowCheckout}>
        <UndoDisclaimer past={past} undoAction={undoCartAction} />

        <CartList
          products={productsArr}
          handleCountChangeFor={handleCountChangeFor}
          handlePropagateState={handlePropagateState}
        />
      </CartLists>

      {shouldShowCheckout && (
        <CartCheckout
          total={total}
          priceMode={priceMode}
          isLoading={isLoading}
          longestDelay={longestDelay}
          invalidProducts={invalidProductsArr}
        />
      )}
    </>
  );
};

Cart.propTypes = {
  past: PropTypes.objectOf(PropTypes.any),
  present: PropTypes.objectOf(PropTypes.any).isRequired,
  usersState: PropTypes.shape({
    currentUser: PropTypes.objectOf(PropTypes.any),
  }).isRequired,

  setCartItemCount: PropTypes.func.isRequired,
  undoCartAction: PropTypes.func.isRequired,
  clearCartHistory: PropTypes.func.isRequired,
  fetchSingleProduct: PropTypes.func.isRequired,
  clearCart: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  past: null,
};

export const ConnectedCart = connect(
  state => ({ ...state.cart, usersState: state.users }),
  {
    ...CART_ACTIONS,
    ...ORDER_ACTIONS,
    ...PRODUCT_ACTIONS,
  },
)(Cart);

export default {
  path: Paths.Cart(),
  name: 'Panier',
  component: ConnectedCart,
};
