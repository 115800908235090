import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import PlusIcon from '../../../assets/img/PlusIcon';
import UpArrowIcon from '../../../assets/img/UpArrowIcon';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { getEnv } from '../../utils/global';
import Paths from '../routes/paths';
import ErrorBoundary from '../utils/ErrorBoundary';
import Button from './Button';
import FadedImage from './FadedImage';
import SearchInput from './SearchInput';

const HeaderWrapper = styled.header`
  display: block;
  position: relative;
  width: 100%;
  min-height: 650px;
  height: ${props =>
    props.fullHeight ? '100vh' : `calc(100vh - ${Sizing.headerHeight})`};
  grid-column: 1 / span 12;

  z-index: 10;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    min-height: 530px;
  }

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    height: calc(100vh - 100px);
    min-height: unset;
  }
`;

const HeaderInner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  transform: translateX(-50%);

  background-color: ${Colors.accent};

  svg.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0.6;
    filter: saturate(0);

    z-index: -1;
  }
`;

const HeroContents = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: ${Sizing.wrapperWidth};
  padding-bottom: 120px;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    padding: 0 ${Sizing.gutterWidth};
    padding-bottom: 80px;
  }
`;

const HeroContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    svg {
      width: 24px;
      height: 24px;
      top: 20px !important;
      left: calc(100% + 10px) !important;
    }
  }
`;

const HeroTitle = styled.span`
  position: relative;
  font-size: 15px;
  line-height: 14px;
  color: ${Colors.white};
  font-weight: bold;

  ${props =>
    props.first &&
    css`
      transform: translateY(30px);
    `};

  ${props =>
    props.large &&
    css`
      font-size: 160px;
      line-height: 240px;

      svg {
        position: absolute;
        top: 0;
        left: calc(100% + 35px);
      }
    `};

  ${props =>
    props.last &&
    css`
      transform: translateY(-25px);
      margin-left: 150px;
    `};

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    ${props =>
      props.large &&
      css`
        font-size: 48px;
        line-height: 120px;
      `}
  }
`;

const HeroContentLine = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const HeroScrollArrow = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    display: none;
  }
`;

const HeroContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 460px;

  color: ${Colors.white};

  strong {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    position: relative;
    width: unset;
    margin: 0 auto;
    max-width: 500px;
    margin-top: 20px;
  }
`;

const HeroSearch = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  transform: translate(-50%, -50%);

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    form {
      width: unset;
      margin-right: 20px !important;

      input {
        width: calc(((100vw - 20px) / 4) * 2);
      }
    }
  }

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    width: calc(100% - 32px);

    form {
      width: 100%;
      margin-right: 0 !important;

      input {
        width: 100%;
      }
    }
  }
`;

const HeroButtonLink = styled(Button)`
  box-shadow: none;
  font-size: 15px;
  font-weight: bold;
  height: 40px;
  min-width: 180px;

  white-space: nowrap;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    display: none !important;
  }
`;

const HeroHeader = ({ showSearch, fullHeight, children }) => {
  const history = useHistory();

  const handleOnSearch = value => {
    history.push(`${Paths.Shop()}/?q=${value}`);
  };

  return (
    <HeaderWrapper fullHeight={fullHeight ? 1 : 0}>
      <HeaderInner className="INNER">
        {children || (
          <>
            <FadedImage
              src="/public/img/hero_header.jpg"
              id="hero-header"
              width={150}
              x={-25}
            />

            <HeroContents>
              <HeroContentTitle>
                <HeroTitle first>
                  {appConfig.get('content.pages.home.title.segments.0')}
                </HeroTitle>

                <HeroTitle large>
                  <span>
                    {appConfig.get('content.pages.home.title.segments.1')}
                  </span>
                  <PlusIcon size={58} />
                </HeroTitle>

                <HeroTitle last>
                  {appConfig.get('content.pages.home.title.segments.2')}
                </HeroTitle>
              </HeroContentTitle>
              <HeroContentLine>
                <HeroScrollArrow>
                  <UpArrowIcon
                    angle={180}
                    includeCircle={false}
                    color={Colors.white}
                  />
                </HeroScrollArrow>

                {getEnv('vitaeEnv', 'VITAE_ENV') === 'preprod' && (
                  <HeroContentDescription>
                    <strong>SITE EN PRÉPRODUCTION</strong>
                    {/* <p>
                      Lorem Ipsum has been the industry&apos;s standard dummy
                      text ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book. It has survived not only five centuries.
                    </p> */}
                  </HeroContentDescription>
                )}
              </HeroContentLine>
              {showSearch && (
                <ErrorBoundary>
                  <HeroSearch>
                    <SearchInput
                      style={{ marginRight: '120px' }}
                      onSearch={handleOnSearch}
                      suggest
                    />

                    <HeroButtonLink
                      to={Paths.Shop()}
                      fromComponent={Link}
                      background={Colors.primary}
                      color={Colors.white}
                    >
                      Tous les produits
                    </HeroButtonLink>
                  </HeroSearch>
                </ErrorBoundary>
              )}
            </HeroContents>
          </>
        )}
      </HeaderInner>
    </HeaderWrapper>
  );
};

HeroHeader.propTypes = {
  showSearch: PropTypes.bool,
  fullHeight: PropTypes.bool,
  children: PropTypes.node,
};

HeroHeader.defaultProps = {
  showSearch: true,
  fullHeight: false,
  children: null,
};

export default HeroHeader;
