import PropTypes from 'prop-types';
import React from 'react';

const PlusIcon = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 50 50"
  >
    <g fill="#7CC2ED" fillRule="evenodd">
      <path d="M2.687 17.007A2.687 2.687 0 000 19.694V30.44a2.687 2.687 0 002.687 2.687h13.991v13.991a2.687 2.687 0 002.687 2.688h10.747a2.687 2.687 0 002.687-2.688V35.034c-3.982-.436-7.718-2.842-9.707-6.328-.798-1.397-1.307-2.908-1.8-4.369-.496-1.47-.964-2.856-1.695-4.112a10.504 10.504 0 00-2.945-3.218H2.687z" />
      <path d="M25.076 27.433c-.706-1.236-1.165-2.595-1.65-4.034-.504-1.494-1.025-3.04-1.853-4.462-1.09-1.87-2.693-3.422-4.566-4.504V2.687A2.687 2.687 0 0119.693 0H30.44a2.687 2.687 0 012.687 2.687v13.991h13.991a2.687 2.687 0 012.688 2.687v10.747a2.687 2.687 0 01-2.688 2.687H33.235c-3.35-.397-6.484-2.43-8.16-5.366" />
    </g>
  </svg>
);

PlusIcon.propTypes = {
  size: PropTypes.number,
};

PlusIcon.defaultProps = {
  size: 15,
};

export default PlusIcon;
