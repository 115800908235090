import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { Status } from '../../../store/models/europrisme';
import { Colors } from '../../../styles/vars';
import { parsePriceString } from '../../../utils/misc';
import { connect } from '../../../utils/redux';
import ErrorBoundary from '../../utils/ErrorBoundary';
import BlockPlaceholder from '../BlockPlaceholder';

export const PriceMode = {
  PRODUCT_ARCHIVE: 'PRODUCT_ARCHIVE',
  PRODUCT_SINGLE: 'PRODUCT_SINGLE',
  CART: 'CART',
  CHECKOUT: 'CHECKOUT',
  HOMEPAGE_FEATURED: 'HOMEPAGE_FEATURED',
};

const baseProps = {
  product: PropTypes.shape({ getPrice: PropTypes.func }),
  count: PropTypes.number,
  priceMode: PropTypes.oneOf(['HT', 'TTC']).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};

const baseDefaultProps = { product: null, count: 0, style: {} };

const GenericPrice = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media print {
    &:not(:last-child) {
      margin-bottom: 0;
    }

    &.unit-price-row {
      .price-wrapper {
        opacity: 0.8;

        .children {
          margin-left: 0;

          font-size: 8px;
          line-height: 9px;
        }

        .price {
          font-size: 12px;
        }
      }
    }
  }
`;

const Cell = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 100%;

  color: ${props => (props.promo ? Colors.primary : Colors.darkGrey)};

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.best-price {
    text-align: right;
  }

  ${props =>
    props.strikethrough &&
    css`
      transform-origin: center bottom;
      opacity: 0.6;
    `}

  .price-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;

    @media print {
      flex-direction: row-reverse;
    }

    .price {
      font-size: ${props => (props.large ? 46 : 30)}px;
      line-height: ${props => (props.large ? 52 : 32)}px;
      font-weight: bold;
      opacity: 0.75;

      @media print {
        font-weight: 500;
      }

      &.small {
        font-size: 22px;
        line-height: 26px;

        @media print {
          font-size: 18px;
          line-height: 22px;
        }
      }

      ${props =>
        props.strikethrough &&
        css`
          text-decoration: line-through;
        `}
    }

    .children {
      margin-left: 10px;

      font-size: 8px;
      line-height: 9px;
      font-weight: 300;

      @media print {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      .label {
        margin-bottom: 2px;
        font-size: 12px;
        line-height: 14px;
        opacity: 0.75;
      }

      .price-wrapper {
        .price,
        .children:not(.small) {
          font-size: ${props.large ? 22 : 16}px;
          line-height: ${props.large ? 26 : 18}px;
          opacity: 1;
          font-weight: 400;
        }

        .children {
          margin-left: 6px;
        }
      }

      .sub {
        position: relative;
        display: inline-block;

        font-size: 9px;
        line-height: normal;

        overflow: hidden;
      }

      @media print {
        text-align: right;
      }
    `}
`;

const PromoBadge = styled.span`
  display: inline-block;
  padding: 6px 12px;

  font-size: 12px;
  text-transform: uppercase;
  color: ${Colors.white};
  background-color: ${Colors.primary};
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.5);
  border-radius: 40px;
`;

const formatPrice = number =>
  `${parsePriceString(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}€`;

const getProductPriceByMode = ({ product, count, priceMode }) => {
  switch (priceMode) {
    case 'TTC': {
      const {
        sPromoTTC,
        sBestPromoQuantity,
        sBestPromoTTC,
        sBestPrixQuantity,
        sBestPrixTTC,
        sPrixTTC,
      } = product.getPrice(count);

      return {
        promo: sPromoTTC,
        bestPromoQuantity: sBestPromoQuantity,
        bestPromo: sBestPromoTTC,
        bestPrixQuantity: sBestPrixQuantity,
        bestPrix: sBestPrixTTC,
        prix: sPrixTTC,
      };
    }

    case 'HT':
    default: {
      const {
        sPromoHT,
        sBestPromoQuantity,
        sBestPromoHT,
        sBestPrixQuantity,
        sBestPrixHT,
        sPrixHT,
      } = product.getPrice(count);

      return {
        promo: sPromoHT,
        bestPromoQuantity: sBestPromoQuantity,
        bestPromo: sBestPromoHT,
        bestPrixQuantity: sBestPrixQuantity,
        bestPrix: sBestPrixHT,
        prix: sPrixHT,
      };
    }
  }
};

const PriceLoader = ({ height, ...props }) => (
  <BlockPlaceholder
    height={height || '100%'}
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      borderRadius: 3,
      zIndex: 100,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

PriceLoader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PriceLoader.defaultProps = {
  height: undefined,
};

const SingleProductPrice = ({ product, count, priceMode, style }) => {
  const {
    bestPrix,
    bestPrixQuantity,
    bestPromo,
    bestPromoQuantity,
  } = getProductPriceByMode({ product, priceMode, count: 0 });

  const { prix, promo } = getProductPriceByMode({
    product,
    priceMode,
    count,
  });

  const basePrice = parsePriceString(promo || prix);
  const basePriceWithCount = formatPrice(basePrice * count);

  const isLoaded =
    basePrice &&
    basePrice !== 0 &&
    ![Status.LOADING, Status.REFRESHING].includes(product?.status);

  return (
    <GenericPrice className="generic-price single-product-price" style={style}>
      <Row>
        {bestPromo && (
          <Cell className="promo-badge">
            <PromoBadge>Promotion</PromoBadge>
          </Cell>
        )}

        <Cell vertical strikethrough={!!bestPromo} className="best-price">
          <div className="label">Meilleur prix</div>
          <div
            className="price-wrapper"
            style={{ height: 18, minWidth: '17ch' }}
          >
            <PriceLoader height="18px">
              {isLoaded && (
                <>
                  <div className="price">{formatPrice(bestPrix)}</div>
                  <div className="children">{priceMode}</div>
                </>
              )}
            </PriceLoader>
          </div>

          <AnimatePresence initial={false}>
            {isLoaded && bestPrixQuantity && (
              <motion.span
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 11, opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="sub"
              >
                (dès {bestPrixQuantity} unités)
              </motion.span>
            )}
          </AnimatePresence>
        </Cell>

        {bestPromo && (
          <Cell vertical className="best-price" promo>
            <div className="label">Meilleur prix</div>
            <div
              className="price-wrapper"
              style={{ height: 18, minWidth: '17ch' }}
            >
              <PriceLoader height="18px">
                {isLoaded && (
                  <>
                    <div className="price">{formatPrice(bestPromo)}</div>
                    <div className="children">{priceMode}</div>
                  </>
                )}
              </PriceLoader>
            </div>

            <AnimatePresence initial={false}>
              {isLoaded && bestPromoQuantity && (
                <motion.span
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 11, opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="sub"
                >
                  (dès {bestPromoQuantity} unités)
                </motion.span>
              )}
            </AnimatePresence>
          </Cell>
        )}
      </Row>

      <Row className="main-price">
        <Cell>
          <div
            className="price-wrapper"
            style={{ height: 32, minWidth: '17ch' }}
          >
            <PriceLoader height="32px">
              {isLoaded && (
                <>
                  <div className="price">{basePriceWithCount}</div>
                  <div className="children">{priceMode}</div>
                </>
              )}
            </PriceLoader>
          </div>
        </Cell>
      </Row>
    </GenericPrice>
  );
};

SingleProductPrice.propTypes = { ...baseProps };
SingleProductPrice.defaultProps = { ...baseDefaultProps };

const ProductPriceOnlyBest = ({ product, priceMode, style, large }) => {
  const {
    bestPrix,
    bestPrixQuantity,
    bestPromo,
    bestPromoQuantity,
  } = getProductPriceByMode({ product, priceMode, count: 0 });

  return (
    <GenericPrice className="generic-price only-best-price" style={style}>
      <Row>
        {bestPromo && (
          <Cell className="promo-badge">
            <PromoBadge>Promotion</PromoBadge>
          </Cell>
        )}

        <Cell
          vertical
          strikethrough={!!bestPromo}
          className="best-price"
          large={!bestPromo && large}
        >
          <div className="label">Meilleur prix</div>
          <div className="price-wrapper">
            <div className="price">{formatPrice(bestPrix)}</div>
            <div className="children">{priceMode}</div>
          </div>

          {bestPrixQuantity && (
            <span className="sub">(dès {bestPrixQuantity} unités)</span>
          )}
        </Cell>

        {bestPromo && (
          <Cell vertical className="best-price" promo large={large}>
            <div className="label">Meilleur prix</div>
            <div className="price-wrapper">
              <div className="price">{formatPrice(bestPromo)}</div>
              <div className="children">{priceMode}</div>
            </div>

            {bestPromoQuantity && (
              <span className="sub">(dès {bestPromoQuantity} unités)</span>
            )}
          </Cell>
        )}
      </Row>
    </GenericPrice>
  );
};

ProductPriceOnlyBest.propTypes = { ...baseProps, large: PropTypes.bool };
ProductPriceOnlyBest.defaultProps = { ...baseDefaultProps, large: false };

const CartProductPrice = ({ product, priceMode, count, style }) => {
  const { prix, promo } = getProductPriceByMode({
    product,
    priceMode,
    count,
  });

  const basePrice = parsePriceString(promo || prix);
  const basePriceWithCount = formatPrice(basePrice * count);

  const isLoaded =
    basePrice &&
    basePrice !== 0 &&
    ![Status.LOADING, Status.REFRESHING].includes(product?.status);

  return (
    <GenericPrice
      className="generic-price cart-product-price"
      style={{ ...style, width: '100%', justifyContent: 'flex-end' }}
    >
      <Row className="unit-price-row">
        <Cell className="best-price" vertical>
          <div
            className="price-wrapper"
            style={{ height: 18, minWidth: '17ch' }}
          >
            <PriceLoader height="18px">
              {isLoaded && (
                <>
                  <div className="price">{formatPrice(basePrice)}</div>
                  <div className="children small">Prix unitaire</div>
                </>
              )}
            </PriceLoader>
          </div>
        </Cell>
      </Row>

      <Row className="main-price">
        <Cell>
          <div
            className="price-wrapper"
            style={{ height: 26, minWidth: '17ch' }}
          >
            <PriceLoader height="26px">
              {isLoaded && (
                <>
                  <div className="price small">{basePriceWithCount}</div>
                  <div className="children">{priceMode}</div>
                </>
              )}
            </PriceLoader>
          </div>
        </Cell>
      </Row>
    </GenericPrice>
  );
};

CartProductPrice.propTypes = { ...baseProps };
CartProductPrice.defaultProps = { ...baseDefaultProps };

const ProductPrice = ({ product, count, mode, style, currentUser }) => {
  const { priceMode } = currentUser;

  const renderPriceComponent = () => {
    switch (mode) {
      case PriceMode.PRODUCT_SINGLE: {
        return (
          <SingleProductPrice
            product={product}
            count={count}
            priceMode={priceMode}
            style={style}
          />
        );
      }
      case PriceMode.PRODUCT_ARCHIVE: {
        return (
          <ProductPriceOnlyBest
            product={product}
            priceMode={priceMode}
            style={style}
          />
        );
      }
      case PriceMode.HOMEPAGE_FEATURED: {
        return (
          <ProductPriceOnlyBest
            product={product}
            priceMode={priceMode}
            style={style}
            large
          />
        );
      }
      case PriceMode.CART: {
        return (
          <CartProductPrice
            product={product}
            count={count}
            priceMode={priceMode}
            style={style}
          />
        );
      }
      default: {
        return <div style={style}>{'{Unknown price mode}'}</div>;
      }
    }
  };

  return <ErrorBoundary>{renderPriceComponent()}</ErrorBoundary>;
};

ProductPrice.propTypes = {
  ...baseProps,

  priceMode: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(PriceMode)).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any),
};

ProductPrice.defaultProps = {
  ...baseDefaultProps,

  priceMode: 'HT',
  currentUser: { priceMode: 'HT' },
};

export default connect(state => ({ ...state.users }), {})(memo(ProductPrice));
