import { isEmpty, omit } from 'lodash';

import { undoable } from '../../utils/redux';
import {
  CART_ADD_INVALID_ITEM,
  CART_ADD_ITEM,
  CART_ASSIGN_ITEM_TO_LOCATION,
  CART_CLEAR_ALL_ITEMS,
  CART_CLEAR_HISTORY,
  CART_REMOVE_ITEM,
  CART_RESTORE_REJECTED,
  CART_RESTORE_REQUESTED,
  CART_RESTORE_RESOLVED,
  CART_SAVE_CURRENT_TO_STORAGE,
  CART_SET_ITEM_COMMENT,
  CART_SET_ITEM_COUNT,
  CART_UNDO_ACTION,
  CART_UPDATE_ITEM_DATA,
} from '../actions/types';
import { Keys, storageGet, storagePut } from '../models/localstorage';

export const Status = {
  IDLE: 'IDLE',
  RESTORING: 'RESTORING',
  UPDATING: 'UPDATING',

  LOADING: 'LOADING',
  LOADED: 'LOADED',
  AUTHENTICATING: 'AUTHENTICATING',
  AUTHENTICATED: 'AUTHENTICATED',
  ERROR: 'ERROR',
};

const initialState = {
  products: {},
  invalidProducts: {},
  status: Status.IDLE,
  error: null,

  virtual: { status: Status.IDLE },
};

const saveCartToStorage = ({ products, invalidProducts }, hard = false) => {
  const {
    products: previousProducts,
    invalidProducts: previousInvalidProducts,
  } = storageGet(Keys.CART);

  const selectNonEmpty = (...args) => {
    return args.find(value => !isEmpty(value)) || {};
  };

  if (hard) {
    storagePut(Keys.CART, { products, invalidProducts });
  } else {
    storagePut(Keys.CART, {
      products: selectNonEmpty(products, previousProducts),
      invalidProducts: selectNonEmpty(invalidProducts, previousInvalidProducts),
    });
  }
};

const cartProductExcludedProps = ['pdfFiles', 'pdfStatus'];

export const Cart = (state = initialState, action) => {
  switch (action.type) {
    case CART_RESTORE_REQUESTED:
      return { ...state, status: Status.RESTORING };

    case CART_RESTORE_RESOLVED: {
      const { products, invalidProducts } = action.payload;

      const mappedProducts = Object.entries(products || {})
        .map(([key, product]) => [
          key,
          {
            ...omit(product, cartProductExcludedProps),
            getPrice(quantity, defaultValue = {}) {
              return (
                (this.priceData && this.priceData[quantity]) || defaultValue
              );
            },
          },
        ])
        .reduce((acc, [key, product]) => {
          acc[key] = product;

          return acc;
        }, {});

      return {
        ...state,
        products: mappedProducts || {},
        invalidProducts: invalidProducts || {},
        error: null,
        status: Status.IDLE,
      };
    }

    case CART_RESTORE_REJECTED: {
      const { error } = action.payload;

      return {
        ...state,
        products: {},
        status: Status.IDLE,
        initialOrderComment: '',
        error,
      };
    }

    case CART_ADD_ITEM: {
      const { product, count: addedCount } = action.payload;
      const { sCode, primaryKey } = product;
      const key = primaryKey || sCode;

      const products = { ...state.products };

      if (products[key]) {
        // Handle add product with specific quantities (from external platform)
        if (product.hasSpecificQuantities && product.quantities) {
          if (products[key].quantities) {
            // If product in cart already has specific quantities
            Object.entries(product.quantities).forEach(([prop, value]) => {
              products[key].quantities[prop] += Number.parseInt(value, 10);
            });

            products[key].count +=
              Number.parseInt(product.quantities?.boxes, 10) || addedCount;
          } else {
            // Else convert to specific quantities
            products[key].hasSpecificQuantities = true;
            products[key].quantities = product.quantities;
            products[key].count =
              Number.parseInt(product.quantities?.boxes, 10) || addedCount;
          }
        } else if (
          products[key].hasSpecificQuantities &&
          products[key].quantities
        ) {
          // If added product is basic and cart product has specific quantities
          const prevCartonsCount = Number.parseInt(
            products[key].quantities.cartons,
            10,
          );

          const prevBoxesCount = Number.parseInt(
            products[key].quantities.boxes,
            10,
          );

          const prevUnitsCount = Number.parseInt(
            products[key].quantities.units,
            10,
          );

          const boxesMultiplier = prevBoxesCount / prevCartonsCount;
          const unitsMultiplier = prevUnitsCount / prevCartonsCount;

          const addedBoxes = addedCount * boxesMultiplier;
          const addedUnits = addedCount * unitsMultiplier;

          products[key].quantities.cartons = prevCartonsCount + addedCount;
          products[key].quantities.boxes = prevBoxesCount + addedBoxes;
          products[key].quantities.units = prevUnitsCount + addedUnits;

          products[key].count += addedBoxes;
        } else {
          products[key].count += addedCount;
        }
      } else {
        products[key] = {
          ...omit(product, cartProductExcludedProps),
          count: addedCount,
        };
      }

      products[key].stamp = Date.now();

      saveCartToStorage({ products });

      return { ...state, products };
    }

    case CART_ADD_INVALID_ITEM: {
      const { product, count } = action.payload;
      const { id, sCode } = product;
      const code = id || sCode;

      const products = { ...state.invalidProducts };

      if (products[code]) {
        products[code].count += count;
      } else {
        products[code] = {
          ...omit(product, cartProductExcludedProps),
          count,
        };
      }

      saveCartToStorage({ invalidProducts: products });

      return { ...state, invalidProducts: products };
    }

    case CART_REMOVE_ITEM: {
      const { product, options } = action.payload;

      if (options.invalid) {
        const { id, sCode } = product;
        const code = id || sCode;

        const { [code]: _, ...invalidProducts } = { ...state.invalidProducts };

        saveCartToStorage({ invalidProducts });

        return { ...state, invalidProducts };
      }

      const { sCode } = product;
      const { [sCode]: _, ...products } = { ...state.products };

      saveCartToStorage({ products });

      return { ...state, products };
    }

    case CART_CLEAR_ALL_ITEMS: {
      saveCartToStorage({ products: {}, invalidProducts: {} }, true);

      return {
        ...state,
        products: {},
        invalidProducts: {},
      };
    }

    case CART_SET_ITEM_COUNT: {
      const { product, count, add, skipSort } = action.payload;
      const { sCode } = product;
      const filteredProduct = omit(product, cartProductExcludedProps);

      const { [sCode]: previousProduct, ...products } = { ...state.products };
      const previousCount = previousProduct?.count;

      const newProducts = {
        ...products,
        [sCode]: {
          ...filteredProduct,
          count: add ? (previousCount || 0) + count : count,
        },
      };

      if (!skipSort) {
        newProducts[sCode].stamp = Date.now();
      }

      saveCartToStorage({ products: newProducts });

      return { ...state, products: newProducts };
    }

    case CART_UPDATE_ITEM_DATA: {
      const { product, skipSort } = action.payload;
      const { sCode } = product;
      const filteredProduct = omit(product, cartProductExcludedProps);

      const products = { ...state.products };

      const foundProducts = Object.entries(products).filter(
        // eslint-disable-next-line no-unused-vars
        ([_, p]) => `${p.sCode}` === `${sCode}`,
      );

      if (foundProducts && foundProducts.length > 0) {
        const newProducts = {
          ...products,
          ...foundProducts.reduce((acc, [k, foundProduct]) => {
            const { priceData } = foundProduct;
            const filteredFoundProduct = omit(
              foundProduct,
              cartProductExcludedProps,
            );

            const newProduct = {
              ...filteredFoundProduct,
              ...filteredProduct,
              priceData: { ...priceData, ...product.priceData },
            };

            if (!skipSort) {
              newProduct.stamp = Date.now();
            }

            return { ...acc, [k]: newProduct };
          }, {}),
        };

        saveCartToStorage({ products: newProducts });
        return { ...state, products: newProducts };
      }

      return { ...state };
    }

    case CART_SAVE_CURRENT_TO_STORAGE: {
      const { products } = state;

      saveCartToStorage({ products });

      // Return same state object to prevent useless rerenders
      return state;
    }

    case CART_SET_ITEM_COMMENT: {
      const { itemID, comment } = action.payload;

      if (itemID in state.products) {
        const newProducts = {
          ...state.products,
          [itemID]: {
            ...state.products[itemID],
            sBelowComment: comment,
          },
        };

        saveCartToStorage({ products: newProducts });

        return {
          ...state,
          products: newProducts,
        };
      }

      return state;
    }

    case CART_ASSIGN_ITEM_TO_LOCATION: {
      const { itemID, kIDEntity, iEntityType } = action.payload;

      if (itemID in state.products) {
        const item = state.products[itemID];

        const bareProductData = Object.keys(state.products[itemID])
          .filter(key => !['kIDEntity', 'iEntityType'].includes(key))
          .reduce((acc, key) => {
            acc[key] = item[key];

            return acc;
          }, {});

        const newProduct = (() => {
          if (kIDEntity && iEntityType) {
            return { ...bareProductData, kIDEntity, iEntityType };
          }

          return { ...bareProductData };
        })();

        const newProducts = {
          ...state.products,
          [itemID]: newProduct,
        };

        saveCartToStorage({ products: newProducts });

        return {
          ...state,
          products: newProducts,
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default undoable(Cart, initialState, {
  triggerOnActions: [CART_REMOVE_ITEM],
  undoAction: CART_UNDO_ACTION,
  onUndoAction: saveCartToStorage,
  clearAction: CART_CLEAR_HISTORY,
  onClearAction: state => saveCartToStorage(state, true),
});
