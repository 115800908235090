import PropTypes from 'prop-types';
import { publish } from 'pubsub-js';
import React, { useState } from 'react';
import styled from 'styled-components';

import { PUBSUB_TOPICS, useSubscriber, useTimeout } from '../../hooks';
import { Colors, Sizing } from '../../styles/vars';
import { generateUUID } from '../../utils/misc';
import ErrorBoundary from '../utils/ErrorBoundary';

export const NotificationType = {
  DEFAULT: 'DEFAULT',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

const Area = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  top: 90px;
  right: 20px;

  z-index: 9000;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    top: unset;
    bottom: 30px;
    right: unset;
    left: 0;
    width: 100vw;
    padding: 0 16px;
  }
`;

const NotificationColors = {
  [NotificationType.DEFAULT]: {
    background: Colors.white,
    color: Colors.black,
  },
  [NotificationType.INFO]: {
    background: Colors.white,
    color: Colors.black,
  },
  [NotificationType.SUCCESS]: {
    background: Colors.primary,
    color: Colors.white,
  },
  [NotificationType.WARNING]: {
    background: Colors.lightYellow,
    color: Colors.darkYellow,
  },
  [NotificationType.ERROR]: {
    background: Colors.lightRed,
    color: Colors.darkRed,
  },
};

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 45px;
  min-width: 250px;
  padding: 8px 20px;
  margin: 5px 0;

  font-size: 14px;
  border-radius: 22px;
  color: ${props => props.type && NotificationColors[props.type].color};
  background-color: ${props =>
    props.type && NotificationColors[props.type].background};

  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    width: 100%;
    justify-content: space-between;
  }

  &:first-of-type {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-top: 0;
  }

  &.out {
    transform: translateX(20px);
    opacity: 0;
    pointer-events: none;
  }

  &.in {
    transform: translateX(0);
    opacity: 1;
    pointer-events: initial;
  }
`;

const NotificationDismissButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 8px;
  flex-shrink: 0;

  background: none;
  border: none;
  outline: none;

  &::-moz-focus-inner {
    border: none;
    outline: none;
  }

  cursor: pointer;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 120%;
    width: 1px;

    transform-origin: center;
    background-color: currentColor;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const NotificationContents = styled.div`
  flex-grow: 1;
`;

export const sendNotification = (
  label,
  type = NotificationType.DEFAULT,
  timeout = 10000,
) => {
  const uuid = generateUUID();

  publish(PUBSUB_TOPICS.NOTIFICATION_PUSHED, { label, uuid, type, timeout });
};

const Notification = ({ uuid, timeout, onTimeout, type, children }) => {
  const [delay, setDelay] = useState(timeout);
  const [status, setStatus] = useState('out');

  useTimeout(() => setStatus('in'), 100, []);

  useTimeout(() => setStatus('out'), delay + 100, [onTimeout, delay]);

  useTimeout(() => onTimeout && onTimeout(uuid), delay + 500, [
    onTimeout,
    delay,
  ]);

  const dismissNow = () => {
    setDelay(0);
  };

  return (
    <NotificationContainer className={status} type={type}>
      <NotificationContents>{children}</NotificationContents>
      <NotificationDismissButton onClick={dismissNow} />
    </NotificationContainer>
  );
};

Notification.propTypes = {
  uuid: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  onTimeout: PropTypes.func,
  type: PropTypes.oneOf(Object.values(NotificationType)),
  children: PropTypes.node.isRequired,
};

Notification.defaultProps = {
  timeout: 5000,
  onTimeout: null,
  type: 'default',
};

const NotificationArea = () => {
  const [notifications, setNotifications] = useState([]);

  useSubscriber(
    PUBSUB_TOPICS.NOTIFICATION_PUSHED,
    (type, notification) => {
      if (type === PUBSUB_TOPICS.NOTIFICATION_PUSHED) {
        setNotifications([...notifications, notification]);
      }
    },
    [notifications],
  );

  const removeNotification = uuid => {
    setNotifications([...notifications.filter(other => other.uuid !== uuid)]);
  };

  return (
    <Area>
      <ErrorBoundary>
        {notifications.map(({ label, uuid, type, timeout }) =>
          label ? (
            <Notification
              key={uuid}
              uuid={uuid}
              type={type}
              timeout={timeout}
              onTimeout={removeNotification}
            >
              {label}
            </Notification>
          ) : null,
        )}
      </ErrorBoundary>
    </Area>
  );
};

NotificationArea.propTypes = {};

export default NotificationArea;
